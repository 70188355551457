import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import RequestHandler from "../../requests/RequestHandler";
import {MutationContainerComponent} from "./MutationContainerComponent";

interface MutationInterfaceComponentProps {
    score: number;
    rr: string;
    hk: string;
    orgName: string;
    sequence: string;
    show: boolean;
    close: () => void;
    mutatedDataSend: (
        histidineKinase: string,
        responseRegulator: string,
        newValue: number,
        newMSA: string
    ) => void;
}

interface MutationInterfaceComponentState {
    newScore?: any;
    textContent: string;
}

export class MutationModalComponent extends React.Component<
    MutationInterfaceComponentProps,
    MutationInterfaceComponentState
> {
    private originalScore = this.props.score;

    constructor(props: MutationInterfaceComponentProps) {
        super(props);
        this.state = {
            newScore: this.originalScore,
            textContent: this.props.sequence,
        };
    }

    handleMutationChange = (val: string) => {
        this.setState({
            ...this.state,
            textContent: val,
        });
    };

    evaluateRequest = () => {
        const store = new RequestHandler();
        store
            .getMutationEvaluation([
                {
                    histidineKinase: this.props.hk,
                    responseRegulator: this.props.rr,
                    msa: this.state.textContent,
                },
            ])
            .then(mutations => {
                if (mutations) {
                    this.props.mutatedDataSend(
                        this.props.hk,
                        this.props.rr,
                        mutations[0].score,
                        this.state.textContent
                    );
                    this.setState({
                        ...this.state,
                        newScore: mutations[0].score,
                    });
                } else {
                    console.error("Score was null");
                }
            });
    };

    render(): JSX.Element | null {
        return (
            <Modal show={this.props.show} onHide={this.props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Mutate Residues</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span
                        style={{
                            display: "inline-block",
                            borderRadius: "100%",
                            width: "1em",
                            height: "1em",
                            backgroundColor: "LightCoral",
                            position: "relative",
                            top: "0.2em",
                        }}
                    />{" "}
                    Histidine Kinase : {this.props.hk} <br />
                    <span
                        style={{
                            display: "inline-block",
                            borderRadius: "100%",
                            width: "1em",
                            height: "1em",
                            backgroundColor: "LightSkyBlue",
                            position: "relative",
                            top: "0.2em",
                        }}
                    />{" "}
                    Response Regulator : {this.props.rr} <br />
                    Hamiltonian Score : {this.state.newScore} <br />
                    Sequence : <br />
                    <MutationContainerComponent
                        defaultValue={this.props.sequence}
                        handleChange={this.handleMutationChange}
                        numCharInRow={30}
                        start={0}
                        charDiff={10}
                        colorPlaces={[
                            {start: 0, end: 67, color: "LightCoral"},
                            {start: 67, end: 179, color: "LightSkyBlue"},
                        ]}
                    />
                    {/* <textarea
                        style={{width: "100%"}}
                        rows={5}
                        spellCheck={false}
                        onChange={this.handleChange}
                        value={this.state.textContent}
                     /> */}
                    {this.state.newScore !== this.originalScore && (
                        <Modal.Body>
                            New Hamiltonian Score : {this.state.newScore}
                        </Modal.Body>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.evaluateRequest}>
                        Evaluate
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
