import React, {Component} from "react";
import RequestHandler from "../../requests/RequestHandler";
import {AdvancedSearchRequestInterface} from "../../requests/RequestInterfaces";
import {AdvancedSearchComponent} from "./AdvancedSearchComponent";
import {BasicPairInterface} from "../../requests/ResponseInterfaces";
import {ExtendedSelectablePair} from "../ListComponent/ExtendedListComponent";
import {QueryMode} from "../../pages/MainPage/MainPageContainer";

export interface AdvancedSearchContainerState {
    organismQuery: string;
    hkQuery: string;
    rrQuery: string;
    msaQuery: string;
    organismQueryMode: QueryMode;
    hkQueryMode: QueryMode;
    rrQueryMode: QueryMode;
    msaQueryMode: QueryMode;
    upperBound: string;
    lowerBound: string;
    resultCount: number;
    organismID: number;
    result?: BasicPairInterface[];
    pageNumber: number;
    loading: boolean;
    searchError: boolean;
}

interface AdvancedSearchContainerProps {
    updateAdvancedSearchResults: (
        searchResults: ExtendedSelectablePair[]
    ) => void;
}

export class AdvancedSearchContainer extends Component<
    AdvancedSearchContainerProps,
    AdvancedSearchContainerState
> {
    store = new RequestHandler();

    constructor(props: AdvancedSearchContainerProps) {
        super(props);
        this.state = {
            organismQuery: "",
            hkQuery: "",
            rrQuery: "",
            msaQuery: "",
            organismQueryMode: QueryMode.CONTAINS,
            hkQueryMode: QueryMode.CONTAINS,
            rrQueryMode: QueryMode.CONTAINS,
            msaQueryMode: QueryMode.CONTAINS,
            upperBound: "",
            lowerBound: "",
            resultCount: 5000,
            organismID: -1,
            pageNumber: 0,
            loading: false,
            searchError: false,
        };
    }

    generateAdvPairRequest = (
        state: AdvancedSearchContainerState
    ): AdvancedSearchRequestInterface => {
        if (
            state.organismQuery !== "" ||
            state.hkQuery !== "" ||
            state.rrQuery !== "" ||
            state.msaQuery !== "" ||
            state.lowerBound !== "" ||
            state.upperBound !== ""
        ) {
            let req: AdvancedSearchRequestInterface = {
                resultCount: state.resultCount,
                organismID: state.organismID,
                pageNumber: state.pageNumber,
            };
            if (state.organismQuery !== "") {
                req.requestByOrganism = {
                    query: state.organismQuery,
                    queryMode: state.organismQueryMode,
                };
            }
            if (state.hkQuery !== "") {
                req.requestByHK = {
                    query: state.hkQuery,
                    queryMode: state.hkQueryMode,
                };
            }
            if (state.rrQuery !== "") {
                req.requestByRR = {
                    query: state.rrQuery,
                    queryMode: state.rrQueryMode,
                };
            }
            if (state.msaQuery !== "") {
                req.requestByMSA = {
                    query: state.msaQuery,
                    queryMode: state.msaQueryMode,
                };
            }
            if (state.lowerBound !== "" || state.upperBound !== "") {
                req.requestByRange = {
                    upperBound: state.upperBound,
                    lowerBound: state.lowerBound,
                };
            }
            return req;
        } else {
            let req: AdvancedSearchRequestInterface = {
                resultCount: state.resultCount,
                organismID: state.organismID,
                pageNumber: state.pageNumber,
            };
            console.error("Target not found");
            return req;
        }
    };

    sendSearchRequest = () => {
        this.setState({
            pageNumber: 0,
            loading: true,
        }); //TODO: WTF
        this.store
            .getPairList(this.generateAdvPairRequest(this.state))
            .then(x => {
                if (x) {
                    this.props.updateAdvancedSearchResults(
                        x.map(pair => {
                            return {
                                ...pair,
                                selected: false,
                            } as ExtendedSelectablePair;
                        })
                    );
                    this.setState({
                        result: x,
                        loading: false,
                        searchError: false,
                    });
                } else {
                    this.setState({loading: false, searchError: true});
                }
            });
    };

    sendPageChangeRequest = (pageDiff: number) => {
        const oldPage = this.state.pageNumber;
        if (pageDiff < 0) {
            if (oldPage <= 0) {
                return;
            }
        } else if (pageDiff > 0) {
            if (this.state.result) {
                if (
                    this.state.resultCount <= 0 ||
                    this.state.result.length < this.state.resultCount
                ) {
                    return;
                }
            } else {
                return;
            }
        }

        this.setState({
            pageNumber: oldPage + pageDiff,
        }); //TODO: WTF!
        this.store
            .getPairList(this.generateAdvPairRequest(this.state))
            .then(x => {
                if (x) {
                    this.setState({result: x, loading: false});
                }
            });
    };

    inputChange = (value: any, name: string, category?: string) => {
        if (category) {
            let prevState = this.state;
            // @ts-ignore
            prevState[name][category] = value;
            //@ts-ignore
            this.setState(prevState);
        } else {
            //@ts-ignore
            this.setState({
                [name]: value,
                searchError: false,
            });
        }
    };

    private handleRadioButtonChange = (
        parameter: string,
        value: QueryMode,
        inputNewState: boolean
    ): void => {
        const prevState = this.state;
        const query: QueryMode = inputNewState ? value : QueryMode.CONTAINS;

        this.setState({...prevState, [parameter]: query} as Pick<
            AdvancedSearchContainerState,
            keyof AdvancedSearchContainerState
        >);
    };

    private clearResults = (): void => {
        this.setState({result: undefined});
    };

    public render(): JSX.Element {
        return (
            <AdvancedSearchComponent
                parentState={this.state}
                searchClick={this.sendSearchRequest}
                pageChangeClick={this.sendPageChangeRequest}
                inputChange={this.inputChange}
                handleRadioButtonChange={this.handleRadioButtonChange}
                clearResults={this.clearResults}
                loading={this.state.loading}
                searchError={this.state.searchError}
                /*updateAdvancedSearchResults={this.props.updateAdvancedSearchResults}*/
            />
        );
    }
}
