import React, {Component} from "react";
import {HeatMapPixel} from "../Pixel/HeatMapPixelComponent";
import {getColorFromGradient} from "../../../utils/colorGradient";
import {SelectablePair} from "../../../requests/adapter";
import "./HeatmapRowStyle.css";
import LabelTooltipComponent from "../../ToolTip/LabelToolTipComponent";

interface HeatMapRowComponentProps {
    data: SelectablePair[];
    title: string;
    min: number;
    max: number;
    label: string;
    displayLabel: string;
    labelsX: Array<string>;
    displayLabelsX: Array<string>;
    rowIndex: number;
    pairsList?: SelectablePair[];
    selectionLookUp: Array<Array<boolean>>;
    toggleItem: (histidine_kinase: string, response_regulator: string) => void;
    mutatePair: (histidine_kinase: string, response_regulator: string) => void;
    gradient: string;
    setPixelRefs: (key: any, ref: any) => void;
    addHistogram: (
        data: Array<number>,
        label: string,
        labelsX: Array<string>,
        organism: string,
        labelBottom: string
    ) => void;
    overlaysBlocked: boolean;
}

export class HeatMapRow extends Component<HeatMapRowComponentProps> {
    public shouldComponentUpdate(
        nextProps: Readonly<HeatMapRowComponentProps>,
        nextState: Readonly<{}>,
        nextContext: any
    ): boolean {
        return (
            JSON.stringify(this.props.selectionLookUp[this.props.rowIndex]) !==
                JSON.stringify(
                    nextProps.selectionLookUp[this.props.rowIndex]
                ) ||
            JSON.stringify(this.props.data) !==
                JSON.stringify(nextProps.data) ||
            this.props.gradient !== nextProps.gradient ||
            this.props.overlaysBlocked !== nextProps.overlaysBlocked
        );
    }

    private selectRow = () => {
        this.props.addHistogram(
            this.props.data.map(pair => pair.score),
            this.props.displayLabel,
            this.props.displayLabelsX,
            this.props.title,
            "Histidine Kinases"
        );
    };

    private renderPixelRow = (doNotDisplay: boolean): JSX.Element => {
        return (
            <>
                {this.props.data.map((dataPoint, columnIndex) => {
                    const isSelected = this.props.selectionLookUp[
                        this.props.rowIndex
                    ][columnIndex];

                    return (
                        <HeatMapPixel
                            cognate={false} //TODO: {dataPoint.cognate}
                            doNotDisplay={doNotDisplay}
                            ref={ref => {
                                this.props.setPixelRefs(
                                    {x: columnIndex, y: this.props.rowIndex},
                                    ref
                                );
                            }}
                            hk={this.props.labelsX[columnIndex]}
                            rr={this.props.label}
                            orgName={this.props.title}
                            key={
                                this.props.labelsX[columnIndex] +
                                "-" +
                                this.props.label
                            }
                            selected={isSelected}
                            color={getColorFromGradient(
                                dataPoint.score,
                                this.props.min,
                                this.props.max,
                                this.props.gradient
                            )}
                            columnIndex={columnIndex}
                            rowIndex={this.props.rowIndex}
                            value={dataPoint.score}
                            toggleItem={this.props.toggleItem}
                            mutatePair={this.props.mutatePair}
                            overlaysBlocked={this.props.overlaysBlocked}
                        />
                    );
                })}
            </>
        );
    };

    public render(): JSX.Element {
        const {pairsList = []} = this.props;
        return (
            <div className={"heatmap-row"}>
                <LabelTooltipComponent
                    name={this.props.label}
                    organism={this.props.title}
                    sequence={pairsList
                        .filter(
                            pair => pair.response_regulator === this.props.label
                        )[0]
                        .msa.substring(67, 179)}
                    domain={
                        pairsList.filter(
                            pair => pair.response_regulator === this.props.label
                        )[0].rr_domain
                    }
                    locus={
                        pairsList.filter(
                            pair => pair.response_regulator === this.props.label
                        )[0].rr_locus
                    }
                    mutatePair={() => {}}
                    addHistogram={this.selectRow}
                    blocked={this.props.overlaysBlocked}
                >
                    <div
                        className={"heatmap-row-label"}
                        onClick={this.selectRow}
                    >
                        {this.props.displayLabel}
                    </div>
                </LabelTooltipComponent>
                {this.renderPixelRow(false)}
            </div>
        );
    }
}
