import React, {Reducer, useReducer} from "react";

export enum PageMode {
    MOBILE = "mobile",
    SMALLSCREEN = "small-screen",
    LARGESCREEN = "large-screen",
}

export interface PageState {
    pageMode: PageMode;
}

export enum PageModeActionType {
    CHANGE = "change",
}

export type PageModeAction = {
    type: PageModeActionType;
    newPageMode: PageMode;
};

const usePageModeHook = (
    initialState: PageState = {pageMode: PageMode.LARGESCREEN}
) => {
    const reducer: Reducer<PageState, PageModeAction> = (
        prevState: PageState,
        action: PageModeAction
    ): PageState => {
        switch (action.type) {
            case PageModeActionType.CHANGE:
                return {
                    ...prevState,
                    pageMode: action.newPageMode,
                };

            default:
                return prevState;
        }
    };

    return useReducer(reducer, initialState);
};

export default usePageModeHook;
