import React, {ReactChildren} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route} from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import {MainPageContainer} from "./pages/MainPage/MainPageContainer";
import {TutorialPageComponent} from "./pages/TutorialPage/TutorialPage";
import {PageModeProvider} from "./providers/PageModeProvider";
import {IntroductionPageComponent} from "./pages/IntroductionPage/IntroductionPage";

const routing = (
    <Router>
        <PageModeProvider>
            <div>
                <Route exact path="/" component={IntroductionPageComponent} />
                <Route path="/run" component={MainPageContainer} />
                <Route exact path="/tutorial" component={TutorialPageComponent} />
            </div>
        </PageModeProvider>
    </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
//
