import React, {Component} from "react";
import "./MainPageStyle.css";

import Dropdown from "../../components/Dropdown/Dropdown";
import {HeatMapComponent} from "../../components/Heatmap/HeatMapComponent";
import {ListComponent} from "../../components/ListComponent/ListComponent";
import {DragBox} from "../../components/DragBox/DragBoxComponent";
import {Alert, Button, Col, Container, Image, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faSpinner,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {useWindowSize} from "../../hooks/WindowSizeHook";

import {SelectablePair} from "../../requests/adapter";
import {HistogramComponentProps} from "../../components/Histogram/HistogramComponent";
import HistogramContainerComponent from "../../components/Histogram/HistogramContainerComponent";
import {MutationModalComponent} from "../../components/MutationBox/MutationModalComponent";
import {OrganismBase} from "../../requests/ResponseInterfaces";
import {AdvancedSearchContainer} from "../../components/AdvancedSearch/AdvancedSearchContainer";
import {
    ExtendedListComponent,
    ExtendedSelectablePair,
} from "../../components/ListComponent/ExtendedListComponent";

import {HeatmapSettingsBar} from "../../components/HeatmapSettings/HeatmapSettingsBar";
import {SortingAlgorithm} from "../../pages/MainPage/MainPageContainer";
import {HeaderComponent} from "./MainMenu/HeaderComponent";
import {Organism} from "../../requests/RequestHandler";
import {usePageMode} from "../../providers/PageModeProvider";
import {PageMode} from "../../hooks/usePageModeHook";

export interface MutationModalData {
    histidineKinase: string;
    responseRegulator: string;
    score: number;
    msa: string;
}

export interface NewHeatmapInterface {
    title: string;
    names: string;
    uniprot: string;
    histidineKinaseLabels: string[];
    responseRegulatorLabels: string[];
    gradient: string;
    pixelSize: number;
    legendSelection: () => void;
}

interface singleVoidCallbackPropResponse {
    callback: (index: number) => void;
}

export class RemoveButtonResponse extends Component<
    singleVoidCallbackPropResponse
> {
    render(): JSX.Element {
        return (
            <Button
                variant="secondary"
                size="sm"
                block
                onClick={() => this.props.callback(-1)}
            >
                <FontAwesomeIcon icon={faTrash} /> Remove
            </Button>
        );
    }
}

interface MainPageComponentProps {
    heatmap?: NewHeatmapInterface;

    pairsList?: SelectablePair[];

    heatmapOrganismList: Organism[];

    setHeatmapRef: (ref: any) => void;
    setPixelRef: (key: any, ref: any) => void;

    exportHeatmap: () => void;
    popHeatmap: (index: number) => void;

    onDragBoxChange: (startCoords: any, endCoords: any) => void;

    unselect: () => void;

    dropDownClickCallback: (id: number, uniprot: string, names: string) => void;
    dropDownFilterChangeCallback: (query: string) => void;
    toggleItem: (histidine_kinase: string, response_regulator: string) => void;
    mutatePair: (histidine_kinase: string, response_regulator: string) => void;

    addHistogram: (
        data: Array<number>,
        label: string,
        labelsX: Array<string>,
        organism: string,
        labelBottom: string
    ) => void;

    histograms?: HistogramComponentProps[];
    deleteHistograms: () => void;

    showMutationModal: boolean;
    closeMutationModal: () => void;
    processMutatedData: (
        histidineKinase: string,
        responseRegulator: string,
        newValue: number,
        newMSA: string
    ) => void;
    mutationModalData: MutationModalData;

    organisms: OrganismBase[];

    advancedSearchResults: ExtendedSelectablePair[];
    updateAdvancedSearchResults: (
        searchResults: ExtendedSelectablePair[]
    ) => void;
    showAdvancedSearchAsHeatmap: () => void;
    showSubselectionAsHeatmap: () => void;

    fixedOrder: boolean;
    overlaysBlocked: boolean;
    sortingAlgorithm: SortingAlgorithm;
    setFixedOrder: (inputChecked: boolean) => void;
    setOverlaysBlocked: (inputChecked: boolean) => void;
    setSortingRadioButton: (id: string, inputChecked: boolean) => void;
    setGradient: (gradient: string) => void;
}

export const MainPageComponent = (props: MainPageComponentProps) => {
    /*    const breakpoint = 576; */
    const fullHeight = 770;

    const {pageState, dispatch} = usePageMode();
    const {pageMode = PageMode.LARGESCREEN} = pageState;

    const renderDropdown = (): JSX.Element => {
        return (
            <div className="Dropdown">
                <Dropdown
                    itemClick={props.dropDownClickCallback}
                    filterChange={props.dropDownFilterChangeCallback}
                    dataIn={props.heatmapOrganismList}
                    title="Select an Organism..."
                />
            </div>
        );
    };

    const renderExtraButtons = (): JSX.Element => {
        return (
            <div style={{width: "100%"}}>
                <Button
                    variant="primary"
                    size="sm"
                    block
                    onClick={props.exportHeatmap}
                >
                    <FontAwesomeIcon icon={faDownload} /> Export to PNG
                </Button>

                <RemoveButtonResponse callback={props.popHeatmap} />
            </div>
        );
    };

    const renderMenuPanel = (): JSX.Element => {
        return (
            <>
                {renderDropdown()}
                {props.heatmap ? renderExtraButtons() : undefined}
            </>
        );
    };

    const unselect = () => {
        props.unselect();
    };

    function renderFileExportPanel(): JSX.Element {
        return (
            <>
                {props.heatmap && props.pairsList ? (
                    <div className="heatmap-whole" key={props.heatmap.title}>
                        <ListComponent
                            data={props.pairsList.filter(pair => pair.selected)}
                            unselect={unselect}
                        />
                    </div>
                ) : (
                    undefined
                )}
            </>
        );
    }

    function renderOrganismEntries(): JSX.Element {
        const {organisms} = props;
        if (organisms.length > 0) {
            return (
                <>
                    <div style={{width: "100%", height: "2em"}} />
                    <ul className={"organism-list"}>
                        <li>
                            <b>
                                Available organisms (#Entries:{" "}
                                {props.organisms.length}):
                            </b>
                        </li>
                        {props.organisms.map(organism => (
                            <li
                                className={"selectable-li"}
                                onClick={() => {
                                    props.dropDownClickCallback(
                                        organism.id,
                                        organism.uniprot,
                                        organism.names
                                    );
                                }}
                            >
                                <span className={"organism-list-uniprot"}>
                                    {organism.uniprot} -&nbsp;
                                </span>
                                <span className={"organism-list-names"}>
                                    {organism.names} &nbsp;
                                </span>
                            </li>
                        ))}
                    </ul>
                </>
            );
        } else {
            return (
                <>
                    <div style={{width: "100%", height: "2em"}} />
                    <ul className={"organism-list"}>
                        <li>
                            <b>Loading ...</b>
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faSpinner}
                                size={"lg"}
                                spin
                            />
                        </li>
                    </ul>
                </>
            );
        }
    }

    function renderOrganismsList(): JSX.Element {
        return <>{renderOrganismEntries()}</>;
    }

    function renderHeatmap(): JSX.Element {
        const {pairsList = []} = props;
        const {heatmap = {} as NewHeatmapInterface} = props;
        if (pairsList.length > 0 && heatmap.histidineKinaseLabels.length > 0) {
            return (
                <DragBox sendBoxData={props.onDragBoxChange}>
                    <div className="HeatmapPage">
                        <div
                            className="heatmap-whole"
                            key={heatmap.title}
                            id={"hw"}
                        >
                            <HeatMapComponent
                                key={heatmap.names + "-heatmap"}
                                ref={refElem => props.setHeatmapRef(refElem)}
                                pairsList={props.pairsList}
                                names={heatmap.names}
                                gradient={heatmap.gradient}
                                title={heatmap.title}
                                labelsX={heatmap.histidineKinaseLabels}
                                labelsY={heatmap.responseRegulatorLabels}
                                pixelSize={heatmap.pixelSize}
                                setPixelRefs={props.setPixelRef}
                                legendSelection={heatmap.legendSelection}
                                toggleItem={props.toggleItem}
                                addHistogram={props.addHistogram}
                                mutatePair={props.mutatePair}
                                overlaysBlocked={props.overlaysBlocked}
                            />
                        </div>
                    </div>
                </DragBox>
            );
        } else {
            return <></>;
        }
    }

    const renderHistograms = (): JSX.Element | null => {
        const {histograms = []} = props;

        if (histograms.length > 0) {
            return (
                <>
                    <HistogramContainerComponent
                        data={histograms}
                        deleteHistograms={props.deleteHistograms}
                    />
                </>
            );
        } else {
            return null;
        }
    };

    const size = useWindowSize();

    const renderFooterPanel = (): JSX.Element => {
        return (
            <>
                <a href={"https://www.utdallas.edu/"}>
                    <Image
                        src="./images/UT_Dallas_black_transp.png"
                        style={{
                            /*position: "fixed",
                            bottom: "220px",
                            left: "2em",*/
                            width: "160px",
                            marginTop: "35px",
                        }}
                    />
                </a>
                <a href={"http://morcoslaboratory.org"}>
                    <Image
                        src="./images/EVI_logoSquare.png"
                        style={{
                            /*position: "fixed",
                            bottom: "2em",*/
                            width: "160px",
                            marginTop: "35px",
                            /*left: "2em",*/
                        }}
                    />
                </a>
            </>
        );
    };

    const renderFooterPanelAlternativeBottom = (): JSX.Element => {
        return (
            <Col
                xs={12}
                style={{
                    borderRight: "1px solid #ececec",
                    backgroundColor: "#f7f7f7",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <a href={"https://www.utdallas.edu/"}>
                    <Image
                        src="./images/UT_Dallas_black_transp.png"
                        style={{
                            width: "200px",
                            margin: "50px 0px 50px 0px",
                        }}
                    />
                </a>
                <a href={"http://morcoslaboratory.org"}>
                    <Image
                        src="./images/EVI_logoSquare.png"
                        style={{
                            width: "200px",
                            margin: "0px 0px 50px 0px",
                        }}
                    />
                </a>
            </Col>
        );
    };

    const closeMutationModal = () => {
        props.closeMutationModal();
    };

    const processMutatedData = (
        histidineKinase: string,
        responseRegulator: string,
        newValue: number,
        newMSA: string
    ): void => {
        props.processMutatedData(
            histidineKinase,
            responseRegulator,
            newValue,
            newMSA
        );
    };

    const renderMutationModal = (): JSX.Element => {
        const {heatmap = {} as NewHeatmapInterface} = props;
        const {title = ""} = heatmap;

        return (
            <MutationModalComponent
                mutatedDataSend={processMutatedData}
                score={props.mutationModalData.score}
                rr={props.mutationModalData.responseRegulator}
                hk={props.mutationModalData.histidineKinase}
                orgName={title}
                sequence={props.mutationModalData.msa}
                show={props.showMutationModal}
                close={closeMutationModal}
            />
        );
    };

    const setFixedOrder = (inputChecked: boolean): void => {
        props.setFixedOrder(inputChecked);
    };

    const setOverlaysBlocked = (inputChecked: boolean): void => {
        props.setOverlaysBlocked(inputChecked);
    };

    const setSortingRadioButton = (id: string, inputChecked: boolean): void => {
        props.setSortingRadioButton(id, inputChecked);
    };

    const setGradient = (gradient: string): void => {
        props.setGradient(gradient);
    };

    function render(): JSX.Element {
        let menuPanelStyle: any = {};
        let menuColumnStyle: any = {};
        const {width = 0, height = 0} = size;
        if (pageMode !== PageMode.MOBILE) {
            menuPanelStyle = {
                /*position: "fixed",
                top: "1em",*/
            };

            menuColumnStyle = {
                minHeight: "100vh",
                borderRight: "1px solid #ececec",
                backgroundColor: "#f7f7f7",
                position: "relative",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "flex",
                flex: "0 0 300px",
                width: "300px",
            };
        } else {
            menuColumnStyle = {
                borderRight: "1px solid #ececec",
                borderBottom: "1px solid #ececec",
                backgroundColor: "#f7f7f7",
                width: "100%",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "flex",
                flex: "0 0 100%",
                paddingBottom: "20px",
            };

            menuPanelStyle = {
                width: "100%",
            };
        }

        const renderLeftPanel = (): JSX.Element => {
            return (
                <div style={menuColumnStyle}>
                    <div style={menuPanelStyle}>
                        <HeaderComponent />

                        {renderMenuPanel()}

                        {pageMode !== PageMode.MOBILE && height > fullHeight
                            ? renderFooterPanel()
                            : undefined}
                    </div>
                </div>
            );
        };

        const diagonalMatrix = (selected = false): Boolean => {
            const {pairsList = []} = props;
            const uniqueResponseRegulators = new Set(
                (selected
                    ? pairsList.filter(pair => pair.selected)
                    : pairsList
                ).map(pair => pair.response_regulator)
            );
            const uniqueHistidineKinases = new Set(
                (selected
                    ? pairsList.filter(pair => pair.selected)
                    : pairsList
                ).map(pair => pair.histidine_kinase)
            );

            const elements = new Set(
                (selected
                    ? pairsList.filter(pair => pair.selected)
                    : pairsList
                ).map(
                    pair =>
                        `${pair.histidine_kinase}-${pair.response_regulator}`
                )
            );

            return (
                elements.size ===
                uniqueHistidineKinases.size * uniqueResponseRegulators.size
            );
        };

        const canDisplayHeatmap = (): Boolean => {
            return (
                props.advancedSearchResults.length < 2800 && diagonalMatrix()
            );
        };

        const canDisplaySubselection = (): Boolean => {
            const {pairsList = []} = props;
            return (
                pairsList.filter(pair => pair.selected).length < 2800 &&
                diagonalMatrix(true)
            );
        };

        const noHeatmapBanner = (): JSX.Element => {
            return (
                <Alert variant={"dark"}>
                    Please select less than 2800 pairs.
                </Alert>
            );
        };

        const showHeatmapBanner = (): JSX.Element => {
            return (
                <Alert variant={"info"}>
                    You can display the data as a heatmap &nbsp;&nbsp;&nbsp;
                    <Button
                        variant="info"
                        onClick={props.showAdvancedSearchAsHeatmap}
                    >
                        DISPLAY AS A HEATMAP
                    </Button>
                </Alert>
            );
        };

        const showSubselectionBanner = (): JSX.Element => {
            return (
                <Alert variant={"info"}>
                    You can display the selection as heatmap &nbsp;
                    <Button
                        variant="info"
                        onClick={props.showSubselectionAsHeatmap}
                    >
                        DISPLAY
                    </Button>
                </Alert>
            );
        };

        const renderAdvancedSearchResults = (
            advancedSearchResults: ExtendedSelectablePair[]
        ) => {
            const heatmapBanner = canDisplayHeatmap()
                ? showHeatmapBanner()
                : noHeatmapBanner();

            return (
                <>
                    {heatmapBanner}
                    <ExtendedListComponent
                        data={advancedSearchResults}
                        unselect={() => {}}
                    />
                </>
            );
        };

        const renderCentralPanel = (): JSX.Element => {
            let centralPanelContents: JSX.Element;
            if (props.pairsList && props.pairsList.length > 0) {
                centralPanelContents = renderHeatmap();
            } else if (props.advancedSearchResults.length > 0) {
                centralPanelContents = renderAdvancedSearchResults(
                    props.advancedSearchResults
                );
            } else {
                centralPanelContents = renderOrganismsList();
            }

            return (
                <div
                    style={{
                        minHeight: "100%",
                        overflowX: "scroll",
                        overflowY: "hidden",
                        padding: "25px 20px 20px",
                        flex: "1 1 500px",
                    }}
                >
                    <AdvancedSearchContainer
                        updateAdvancedSearchResults={
                            props.updateAdvancedSearchResults
                        }
                    />
                    <HeatmapSettingsBar
                        alphabeticalOrder={false}
                        upperLeftCenteredOrder={false}
                        fixedOrder={props.fixedOrder}
                        overlaysBlocked={props.overlaysBlocked}
                        sortingAlgorithm={props.sortingAlgorithm}
                        setFixedOrder={setFixedOrder}
                        setOverlaysBlocked={setOverlaysBlocked}
                        setSortingRadioButton={setSortingRadioButton}
                        setGradient={setGradient}
                    />
                    {centralPanelContents}
                </div>
            );
        };

        const renderRightPanel = (): JSX.Element => {
            const {pairsList = []} = props;
            const selected = pairsList.filter(pair => pair.selected);
            const heatmapBanner =
                selected.length > 0
                    ? canDisplaySubselection()
                        ? showSubselectionBanner()
                        : noHeatmapBanner()
                    : undefined;

            let rightPanel: JSX.Element;
            if (pageMode === PageMode.LARGESCREEN) {
                rightPanel = (
                    <div
                        style={{
                            minHeight: "100%",
                            borderLeft: "1px solid #ececec",
                            backgroundColor: "#f7f7f7",
                            width: "400px",
                            flex: "0 0 400px",
                            padding: "25px 15px",
                        }}
                    >
                        {renderHistograms()}
                        {heatmapBanner}
                        {renderFileExportPanel()}
                    </div>
                );
            } else {
                rightPanel = (
                    <div
                        style={{
                            borderTop: "1px solid #ececec",
                            backgroundColor: "#f7f7f7",
                            width: "100%",
                            padding: "25px 15px",
                        }}
                    >
                        {renderHistograms()}
                        {heatmapBanner}
                        {renderFileExportPanel()}
                    </div>
                );
            }

            return rightPanel;
        };

        return (
            <div className={"main-page"}>
                <div className={"main-page-content"}>
                    <Container fluid>
                        <Row
                            style={{minHeight: "100vh", alignItems: "stretch"}}
                        >
                            {renderLeftPanel()}
                            {renderCentralPanel()}
                            {renderRightPanel()}

                            {pageMode === PageMode.MOBILE
                                ? renderFooterPanelAlternativeBottom()
                                : undefined}
                        </Row>
                    </Container>

                    {props.showMutationModal
                        ? renderMutationModal()
                        : undefined}
                </div>
            </div>
        );
    }

    return render();
};
