import React, {Component} from "react";
import {findDOMNode} from "react-dom";
import domtoimage from "dom-to-image";
import FileSaver from "file-saver";
import HistogramComponent, {
    HistogramComponentProps,
} from "./HistogramComponent";
import {HistogramLegend} from "./HistogramLegendComponent";
import {faDownload, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";

interface HistogramContainerComponentProps {
    data: HistogramComponentProps[];
    deleteHistograms: () => void;
    //gradient: string;
}

export default class HistogramContainerComponent extends Component<
    HistogramContainerComponentProps
> {
    private hsRef: any;

    private exportHistogramToPNG = () => {
        const node = findDOMNode(this.hsRef);
        if (node) {
            domtoimage
                .toBlob(node)
                .then((blob: any) => FileSaver.saveAs(blob, "output.png"));
        }
    };

    private renderHistogramMenuBar = (): JSX.Element => {
        return (
            <div className={"histogram-export-button"}>
                <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => this.exportHistogramToPNG()}
                >
                    <FontAwesomeIcon icon={faDownload} /> Export to PNG
                </Button>
                <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => this.props.deleteHistograms()}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
        );
    };

    public render(): JSX.Element {
        return (
            <div
                className={"histogram-container"}
                style={{backgroundColor: "white"}}
            >
                {this.renderHistogramMenuBar()}
                <div
                    ref={ref => {
                        this.hsRef = ref;
                    }}
                >
                    <div className={"histogram-plot-wrapper"} style={{}}>
                        {this.props.data.map(histogram => (
                            <>
                                <div className="histogram-title">
                                    &nbsp;&nbsp;{histogram.organism}:{" "}
                                    {histogram.title}
                                </div>
                                <div className={"histogram-wrapper"}>
                                    <HistogramLegend
                                        maxVal={Math.min(...histogram.data)}
                                        minVal={Math.max(...histogram.data)}
                                        labelNum={4}
                                        height={100}
                                        key={histogram.title}
                                    />
                                    <HistogramComponent
                                        {...histogram}
                                        key={"histogram" + histogram.title}
                                    />
                                </div>
                                <div className="histogram-x-label">
                                    {histogram.labelBottom}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
