import React, {Component} from "react";
import {ListGroup} from "react-bootstrap";
import {Organism} from "../../requests/RequestHandler";

type DropdownTableWrapperProps = {
    data: Organism[];
    filter: string;
    itemClick: (item: Organism) => void;
};

export class DDTableWrapper extends Component<DropdownTableWrapperProps> {
    private renderItem(data: Organism, itemClick: (item: Organism) => void) {
        return (
            <ListGroup.Item
                action
                key={data.key}
                onClick={() => itemClick(data)}
            >
                {data.displayName}
            </ListGroup.Item>
        );
    }

    render(): JSX.Element {
        return (
            <>
                {!this.props.data || this.props.data.length === 0 ? (
                    <p> No results returned. </p>
                ) : (
                    this.props.data.map(organism =>
                        this.renderItem(organism, this.props.itemClick)
                    )
                )}
            </>
        );
    }
}
