import React, {Component} from "react";
import {coord} from "../HeatMapComponent";
import "./HeatmapPixelStyle.css";
import TooltipComponent from "../../ToolTip/ToolTipComponent";

interface HeatMapPixelComponentProps {
    doNotDisplay: boolean;

    columnIndex: number;
    rowIndex: number;

    selected: boolean;
    color: any;
    value: number;
    toggleItem: (histidine_kinase: string, response_regulator: string) => void;
    mutatePair: (histidine_kinase: string, response_regulator: string) => void;
    orgName: string;
    rr: string;
    hk: string;
    cognate: boolean;
    overlaysBlocked: boolean;
}

interface HeatMapPixelStyleProps {
    color: string;
    doNotDisplay: boolean;
    selected: boolean;
}

export class HeatMapPixel extends Component<HeatMapPixelComponentProps> {
    private readonly pixelRef: any;

    constructor(props: HeatMapPixelComponentProps) {
        super(props);
        this.pixelRef = React.createRef();
    }

    private onLeftClick = (
        histidine_kinase: string,
        response_regulator: string
    ) => {
        this.props.toggleItem(histidine_kinase, response_regulator);
    };

    private onRightClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        this.sendMutation();
    };

    private sendMutation = (): void => {
        this.props.mutatePair(this.props.hk, this.props.rr);
    };

    private HeatMapPixelStyle = (
        props: HeatMapPixelStyleProps
    ): React.CSSProperties => {
        return {
            backgroundColor: props.color, //unused for now because display flex needed. Toggle visible instead if needed.
            /*display: props.doNotDisplay ? "hidden" : undefined,*/ boxShadow: props.selected
                ? "0px 0px 0px 2px red inset"
                : undefined,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };
    };

    public sendLocation = (): coord => {
        const rect = this.pixelRef.current.getBoundingClientRect();
        return {
            x: rect.x,
            y: rect.y,
            histidine_kinase: this.props.hk,
            response_regulator: this.props.rr,
        };
    };

    public sendValue = (): {
        value: number;
        histidine_kinase: string;
        response_regulator: string;
    } => {
        return {
            value: this.props.value,
            histidine_kinase: this.props.hk,
            response_regulator: this.props.rr,
        };
    };

    public render(): JSX.Element {
        return (
            <TooltipComponent
                value={this.props.value}
                hk={this.props.hk}
                rr={this.props.rr}
                mutatePair={this.props.mutatePair}
                blocked={this.props.overlaysBlocked}
            >
                <div
                    className={"heatmap-pixel"}
                    style={this.HeatMapPixelStyle({
                        color: this.props.color,
                        doNotDisplay: this.props.doNotDisplay,
                        selected: this.props.selected,
                    })}
                    ref={this.pixelRef}
                    onContextMenu={this.onRightClick}
                    onClick={event => {
                        return this.onLeftClick(this.props.hk, this.props.rr);
                    }}
                >
                    {this.props.cognate ? (
                        <div className="pixel-cognate"></div>
                    ) : (
                        undefined
                    )}
                </div>
            </TooltipComponent>
        );
    }
}
