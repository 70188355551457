// Called by HBarContainerComponent

import React, {Component} from "react";

interface HBarComponentProps {
    maxVal: number;
    normMaxVal: number;
    value: number;
    normVal: number;
    name: string;
    width: number;
    maxHeight: number;
    color: any;
}

interface HBarComponentStates {
    hover: boolean;
    currentHeight: number;
}

export default class HBarComponent extends Component<
    HBarComponentProps,
    HBarComponentStates
> {
    constructor(props: HBarComponentProps) {
        super(props);
        this.state = {
            hover: false,
            currentHeight: this.calculateHeight(
                this.props.maxHeight,
                this.props.normVal,
                this.props.normMaxVal
            ),
        };
    }

    updateHeight = () => {
        this.setState({
            currentHeight: this.calculateHeight(
                this.props.maxHeight,
                this.props.normVal,
                this.props.normMaxVal
            ),
        });
    };

    calculateHeight = (maxHeight: number, value: number, maxValue: number) => {
        return maxHeight * (value / maxValue);
    };

    getBarStyle = (): React.CSSProperties => {
        return {
            width: this.props.width,
            height: this.state.currentHeight,
            border: "1px solid silver",
            backgroundColor: this.props.color,
        };
    };

    render(): JSX.Element {
        return (
            <div className={"histogram-bar"}>
                <div style={this.getBarStyle()}></div>
                <div
                    className={"label"}
                    style={{
                        fontSize: "8pt",
                        width: this.props.width,
                        height: "10px",
                        fontFamily: "Sans",
                        userSelect: "none",
                    }}
                >
                    {this.props.name}
                </div>
            </div>
        );
    }
}
