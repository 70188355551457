import React, {Component} from "react";
import {MutationTextBoxComponent} from "./MutationTextBoxComponent";
import {MutationBackgroundComponent} from "./MutationBackgroundComponent";
import {MutationBackgroundHighlightComponent} from "./MutationBackgroundHighlightComponent";
import {MutationBackgroundErrorComponent} from "./MutationBackgroundErrorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import "./MutationComponentStyle.css";

interface MutationContainerComponentProps {
    defaultValue: string;
    numCharInRow: number;
    colorPlaces: ColorPlace[];
    start: number;
    charDiff: number;
    handleChange?: (value: string) => void;
}

export interface ColorPlace {
    start: number;
    end: number;
    color: string;
}

interface MutationContainerComponentState {
    value: string;
    rowCount: number;
    errors: string;
    hasErrors: boolean;
    colors: string[];
}

export class MutationContainerComponent extends Component<
    MutationContainerComponentProps,
    MutationContainerComponentState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
            rowCount: this.getNumRows(this.props.defaultValue),
            errors: this.props.defaultValue,
            hasErrors: false,
            colors: this.getColors(),
        };
    }

    private getColors = (): string[] => {
        return this.props.defaultValue.split("").map((char, index) => {
            index += this.props.start;
            let color: string = "transparent";
            this.props.colorPlaces.forEach(place => {
                if (place.start <= index && index <= place.end) {
                    color = place.color;
                }
            });
            return color;
        });
    };

    private handleChange = (val: string) => {
        const {colors} = this.state;
        const errors = this.getErrors(val);
        const hasErrors =
            (errors.match(/1/g) || []).length > 0 ||
            val.length !== this.props.defaultValue.length;

        this.setState({
            colors,
            value: val,
            rowCount: this.getNumRows(val),
            errors,
            hasErrors,
        });
        if (this.props.handleChange) {
            this.props.handleChange(val);
        }
    };

    private getNumRows = (target: string) => {
        let numRows = 5;
        if (Math.ceil(target.length / this.props.numCharInRow) > 5) {
            numRows = Math.ceil(target.length / this.props.numCharInRow);
        }
        return numRows;
    };

    private getErrors = (val: string) => {
        return val.replace(/[^GALMFWKQESPVICYHRNDT\.-]/g, "1");
    };

    private getErrorMessage(): JSX.Element {
        return (
            <div className="mutation-error-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                &nbsp;&nbsp;&nbsp;&nbsp; The new sequence is not valid (
                {this.state.value.length} chars long)
                <ul className="mutation-error-list">
                    <li className="mutation-error-item">
                        only "<b>GALMFWKQESPVICYHRNDT</b>", "<b>.</b>", and ""
                        <b>-</b>" are allowed.
                    </li>
                    <li className="mutation-error-item">
                        final sequence length must be{" "}
                        {this.props.defaultValue.length} chars long.
                    </li>
                </ul>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <>
                <div
                    className={"mutation-page-container"}
                    style={{
                        position: "relative",
                        overflow: "scroll",
                        height: "250px",
                        overflowX: "scroll",
                        lineBreak: "anywhere",
                    }}
                >
                    <div className={"mutation-slider"}>
                        <MutationBackgroundComponent
                            rowCount={this.state.rowCount}
                            numCharInLine={this.props.numCharInRow}
                            colorPlace={this.props.colorPlaces}
                            charOffset={this.props.start}
                            charDiff={this.props.charDiff}
                        />
                        <MutationBackgroundHighlightComponent
                            value={this.state.colors}
                        />
                        <MutationBackgroundErrorComponent
                            value={this.state.errors}
                        />
                        <MutationTextBoxComponent
                            fontSize={20}
                            letterSpacing={2.5}
                            onChange={this.handleChange}
                            rowCount={this.state.rowCount}
                            value={this.state.value}
                        />
                    </div>
                </div>
                {this.state.hasErrors ? this.getErrorMessage() : undefined}
            </>
        );
    }
}
