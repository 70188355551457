import React, {Component} from "react";
import {NothingSelected} from "./NothingSelectedComponent";

import "./ListComponentStyle.css";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Alert, Form} from "react-bootstrap";
import {SelectablePair} from "../../requests/adapter";

export interface ExtendedSelectablePair extends SelectablePair {
    organism: {uniprot: string; names: string};
}

interface ExtendedListComponentProps {
    data?: ExtendedSelectablePair[];
    pageNumber?: number;
    unselect: () => void;
}

export class ExtendedListComponent extends Component<
    ExtendedListComponentProps
> {
    private dataToCSV(data: ExtendedSelectablePair[]) {
        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += `"organism","histidine kinase","response regulator","score"\r\n`;

        data.forEach(obj => {
            let row = `"${obj.organism.uniprot}","${obj.histidine_kinase}","${obj.response_regulator}","${obj.score}"`;
            csvContent += row + "\r\n";
        });

        return csvContent;
    }

    public unselect = () => {
        this.props.unselect();
    };

    private exportData = () => {
        const {data = []} = this.props;

        const csvContent = this.dataToCSV(data);
        const encodedUri = encodeURI(csvContent);

        window.open(encodedUri);
    };

    public render(): JSX.Element {
        const {data = []} = this.props;
        let offset: number;
        if (this.props.pageNumber) {
            offset = this.props.pageNumber * data.length;
        } else {
            offset = 0;
        }

        if (data.length > 0) {
            return (
                <>
                    <table>
                        <tr>
                            <th colSpan={5}>
                                <div className={"table-header-content-wrapper"}>
                                    <Form.Group
                                        controlId="formBasicCheckbox"
                                        style={{margin: "20px"}}
                                    />
                                    <Button
                                        variant="outline-light"
                                        size="sm"
                                        onClick={this.exportData}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileDownload}
                                        />{" "}
                                        Export to CSV
                                    </Button>
                                    &nbsp;
                                    <Button
                                        variant="outline-light"
                                        size="sm"
                                        onClick={this.unselect}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />{" "}
                                        Unselect
                                    </Button>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className={"second-table-header"}>#</th>
                            <th className={"second-table-header"}>Organism</th>
                            <th className={"second-table-header"}>RR</th>
                            <th className={"second-table-header"}>HK</th>
                            <th className={"second-table-header right-aligned"}>
                                &#916;H
                            </th>
                        </tr>
                        {data.map((obj, idx: number) => {
                            idx += 1;
                            return (
                                <tr key={idx + offset}>
                                    <td>{idx + offset}</td>
                                    <td>{obj.organism.uniprot}</td>
                                    <td>{obj.response_regulator}</td>
                                    <td>{obj.histidine_kinase}</td>
                                    <td className={"right-aligned"}>
                                        {obj.score.toFixed(3)}
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </>
            );
        } else {
            return <></>;
        }
    }
}
