import React, {Component} from "react";
import {
    MainPageComponent,
    MutationModalData,
    NewHeatmapInterface,
} from "./MainPage";
import RequestHandler, {Organism} from "../../requests/RequestHandler";
import {coord} from "../../components/Heatmap/HeatMapComponent";
import domtoimage from "dom-to-image";
import FileSaver from "file-saver";
import {findDOMNode} from "react-dom";
import {
    createMapKey,
    MainPageAdapter,
    returnColNoDuplicates,
    SelectablePair,
} from "../../requests/adapter";
import {HistogramComponentProps} from "../../components/Histogram/HistogramComponent";
import {MultipleSequenceAlignment} from "../../requests/RequestInterfaces";
import {OrganismBase} from "../../requests/ResponseInterfaces";
import {ExtendedSelectablePair} from "../../components/ListComponent/ExtendedListComponent";

export interface MainPageContainerProps {}

export enum SortingAlgorithm {
    UPPERLEFT = "upper-left-centered-order",
    ALPHABETICAL = "alphabetical-order",
}

export enum QueryMode {
    START = "start",
    END = "end",
    CONTAINS = "contains",
    MATCHES = "matches",
}

export interface MainPageContainerState {
    heatmap?: NewHeatmapInterface;
    heatmapOrganismList: Organism[];
    selectionLookUp: Array<Array<boolean>>;
    graphDisabled: boolean;
    pairs?: SelectablePair[];
    histidines?: string[];
    regulators?: string[];

    uniprot?: string;
    names?: string;

    histograms?: HistogramComponentProps[];

    showMutationModal: boolean;
    mutationModalData?: MutationModalData;

    organisms: OrganismBase[];

    advancedSearchResults: ExtendedSelectablePair[];

    sortingAlgorithm: SortingAlgorithm;
    fixedOrder: boolean;
    overlaysBlocked: boolean;
    gradient: string;
}

export class MainPageContainer extends Component<
    MainPageContainerProps,
    MainPageContainerState
> {
    private requestHandler: RequestHandler;
    private adapter: MainPageAdapter;
    private readonly heatmapRefArray: any[];
    private pixelRefArray: any[];

    constructor(props: any) {
        super(props);
        this.heatmapRefArray = [];
        this.pixelRefArray = [];

        this.requestHandler = new RequestHandler();
        this.adapter = new MainPageAdapter();

        this.state = {
            heatmapOrganismList: [],
            selectionLookUp: [],
            graphDisabled: true,
            histograms: [],
            showMutationModal: false,
            organisms: [],
            advancedSearchResults: [],
            names: "",
            sortingAlgorithm: SortingAlgorithm.UPPERLEFT,
            fixedOrder: false,
            overlaysBlocked: false,
            gradient:
                "rgb(0,0,255) 0%, rgb(255,255,255) 33%, rgb(255,255,255) 66%, rgb(255,0,0) 100%",
        };
        this.dropDownClickCallback = this.dropDownClickCallback.bind(this);
        this.clearPixelRef = this.clearPixelRef.bind(this);
    }

    public componentDidMount(): void {
        this.requestHandler.getOrganism().then(response => {
            if (response) {
                this.setState({organisms: response});
            }
        });
    }

    public async dropDownClickCallback(
        id: number,
        uniprot: string,
        names: string
    ) {
        this.clearState();
        this.setHeatmapVal(id, uniprot, names);
    }

    public async setHeatmapVal(id: number, uniprot: string, names: string) {
        await this.requestHandler.getPairsOfOrganism(id).then(response => {
            if (response) {
                const pairs = [...this.adapter.selectionAdapter(response)];

                const histidines = returnColNoDuplicates(response, 0).sort();
                const regulators = returnColNoDuplicates(response, 1).sort();

                const sortedValues = this.sortHeatmap(
                    histidines,
                    regulators,
                    pairs,
                    this.state.sortingAlgorithm
                );

                this.setState({
                    pairs: pairs,
                    histidines: sortedValues.histidines,
                    regulators: sortedValues.regulators,
                    uniprot,
                    names,
                });
            }
        });
    }

    private mutationDataReceive = (
        histidineKinase: string,
        responseRegulator: string,
        newValue: number,
        newMSA: string
    ) => {
        let {mutationModalData = {} as MutationModalData} = this.state;
        let {pairs = undefined} = this.state;

        if (pairs) {
            const newPairs: SelectablePair[] = [];
            let oldMSA: string = "";

            pairs.forEach(pair => {
                if (
                    pair.histidine_kinase === histidineKinase &&
                    pair.response_regulator === responseRegulator
                ) {
                    oldMSA = pair.msa;
                    newPairs.push({...pair, score: newValue, msa: newMSA});
                } else {
                    newPairs.push(pair);
                }
            });

            if (oldMSA === newMSA) {
                return;
            }

            pairs = newPairs;
            this.propagateMutation(
                histidineKinase,
                responseRegulator,
                oldMSA,
                newMSA,
                pairs
            ).then(pairs => {
                mutationModalData = {
                    ...mutationModalData,
                    score: newValue,
                    msa: newMSA,
                };

                this.setState({
                    mutationModalData,
                    pairs,
                });
            });
        }
    };

    async propagateMutation(
        histidineKinase: string,
        responseRegulator: string,
        msaBefore: string,
        msaAfter: string,
        pairs: SelectablePair[]
    ): Promise<SelectablePair[]> {
        const responseRegulatorLength = 112;
        const histidineKinaseLength = 64;

        const responseRegulatorBefore = msaBefore.substring(
            histidineKinaseLength
        );
        const responseRegulatorAfter = msaAfter.substring(
            histidineKinaseLength
        );
        const histidineKinaseBefore = msaBefore.substring(
            0,
            histidineKinaseLength
        );
        const histidineKinaseAfter = msaAfter.substring(
            0,
            histidineKinaseLength
        );

        const sequences: MultipleSequenceAlignment[] = [];
        if (responseRegulatorBefore !== responseRegulatorAfter) {
            const filteredPairs = pairs.filter(
                pair =>
                    pair.response_regulator === responseRegulator &&
                    pair.histidine_kinase !== histidineKinase
            );

            filteredPairs.forEach(pair =>
                sequences.push({
                    histidineKinase: pair.histidine_kinase,
                    responseRegulator: pair.response_regulator,
                    msa: `${pair.msa.substring(
                        0,
                        histidineKinaseLength
                    )}${responseRegulatorAfter}`,
                } as MultipleSequenceAlignment)
            );
        }

        if (histidineKinaseBefore !== histidineKinaseAfter) {
            const filteredPairs = pairs.filter(
                pair =>
                    pair.histidine_kinase === histidineKinase &&
                    pair.response_regulator !== responseRegulator
            );

            filteredPairs.forEach(pair =>
                sequences.push({
                    histidineKinase: pair.histidine_kinase,
                    responseRegulator: pair.response_regulator,
                    msa: `${histidineKinaseAfter}${pair.msa.substring(
                        histidineKinaseLength
                    )}`,
                } as MultipleSequenceAlignment)
            );
        }

        if (sequences) {
            let newPairs: SelectablePair[] = [];
            const mutations = await this.requestHandler.getMutationEvaluation(
                sequences
            );

            if (mutations) {
                pairs.forEach(pair => {
                    const match = mutations.filter(
                        mutation =>
                            createMapKey(
                                pair.histidine_kinase,
                                pair.response_regulator
                            ) ===
                            createMapKey(
                                mutation.histidineKinase,
                                mutation.responseRegulator
                            )
                    );
                    if (match.length === 1) {
                        newPairs.push({
                            ...pair,
                            score: match[0].score,
                            msa: match[0].msa,
                        });
                    } else {
                        newPairs.push(pair);
                    }
                });
            }

            return newPairs;
        } else {
            return pairs;
        }
    }

    public exportHeatmap = () => {
        const name: string = this.state.uniprot
            ? this.state.uniprot + ".png"
            : "undefined.png";

        const ref = this.heatmapRefArray[0];
        if (ref) {
            const node = findDOMNode(ref);
            if (node) {
                domtoimage
                    .toBlob(node)
                    .then((blob: any) => FileSaver.saveAs(blob, name));
            }
        } else {
            alert("Please choose an organism first before exporting an image!");
        }
    };

    //*** Clearing Up ***

    public unselect = () => {
        const {pairs = []} = this.state;
        this.setState({
            pairs: pairs.map(pair => {
                return {...pair, selected: false} as SelectablePair;
            }),
        });
    };

    public clearPixelRef() {
        this.pixelRefArray = [];
    }

    private clearState(): void {
        this.unselect();
        this.clearPixelRef();
    }

    public popHeatmap = () => {
        this.setState({
            histidines: undefined,
            regulators: undefined,
            pairs: undefined,
            names: undefined,
            uniprot: undefined,
            selectionLookUp: [],
        });

        this.clearState();
    };

    public dropDownFilterChangeCallback = async (query: string) => {
        if (query === "") {
            this.setState({
                heatmapOrganismList: [],
            });
            return;
        }
        this.requestHandler.getMatchingOrganismList(query).then(data =>
            data
                ? this.setState({
                      heatmapOrganismList: data,
                  })
                : console.error(`Could not find matching organism for ${query}`)
        );
    };

    public setHeatmapRef = (ref: any) => {
        this.heatmapRefArray[0] = ref;
    };

    public setPixelRef = (key: coord, ref: any) => {
        const arrayKey: string = `${key.x}-${key.y}`;
        // @ts-ignore
        this.pixelRefArray[arrayKey] = ref;
    };

    //*** SELECTION LOGIC ***

    private static isPixelInSelection(
        selection: {startCoords: any; endCoords: any},
        location: coord,
        pixelSize: number = 20
    ): boolean {
        return (
            selection.startCoords.x - pixelSize * 0.75 < location.x &&
            location.x < selection.endCoords.x &&
            selection.startCoords.y - pixelSize * 0.75 < location.y &&
            location.y < selection.endCoords.y
        );
    }

    public togglePixel = (
        histidine_kinase: string,
        response_regulator: string
    ): void => {
        const {pairs = []} = this.state;

        this.setState({
            pairs: pairs.map(pair => {
                if (
                    pair.histidine_kinase === histidine_kinase &&
                    pair.response_regulator === response_regulator
                ) {
                    return {...pair, selected: !pair.selected};
                } else {
                    return pair;
                }
            }),
        });
    };

    public onDragBoxChange = (startCoords: any, endCoords: any): void => {
        const pairsWithinDragboxSelection: string[] = [];

        for (const ref in this.pixelRefArray) {
            if (this.pixelRefArray[ref] === null) {
                continue;
            }
            const location = this.pixelRefArray[ref].sendLocation();
            if (
                MainPageContainer.isPixelInSelection(
                    {startCoords, endCoords},
                    location
                )
            ) {
                pairsWithinDragboxSelection.push(
                    createMapKey(
                        location.histidine_kinase,
                        location.response_regulator
                    )
                );
            }
        }

        const {pairs = []} = this.state;

        this.setState({
            pairs: pairs.map(pair => {
                if (
                    pairsWithinDragboxSelection.includes(
                        createMapKey(
                            pair.histidine_kinase,
                            pair.response_regulator
                        )
                    )
                ) {
                    return {...pair, selected: true};
                } else {
                    return pair;
                }
            }),
        });
    };

    public onLabelClick = (threshold: number): void => {
        const {pairs = []} = this.state;
        const pairsWithinTreshold: string[] = [];

        for (const ref in this.pixelRefArray) {
            if (this.pixelRefArray[ref] === null) {
                continue;
            }
            const val = this.pixelRefArray[ref].sendValue();
            if (val.value <= threshold) {
                pairsWithinTreshold.push(
                    createMapKey(val.histidine_kinase, val.response_regulator)
                );
            }
        }

        this.setState({
            pairs: pairs.map(pair => {
                if (
                    pairsWithinTreshold.includes(
                        createMapKey(
                            pair.histidine_kinase,
                            pair.response_regulator
                        )
                    )
                ) {
                    return {...pair, selected: true};
                } else {
                    return pair;
                }
            }),
        });
    };

    //*** Histogram ***

    private addHistogram = (
        data: Array<number>,
        label: string,
        labelsX: Array<string>,
        organism: string,
        labelBottom: string
    ): void => {
        const {histograms = [], pairs = []} = this.state;

        const newHistogram: HistogramComponentProps = {
            data,
            gradient: "rgb(0,0,255) 0%, rgb(255,255,255) 100%",
            height: 100,
            labels: labelsX,
            title: label,
            width: 20,
            maxValue: Math.min(...pairs.map(pair => pair.score)),
            minValue: Math.max(...pairs.map(pair => pair.score)),
            organism,
            labelBottom,
        };

        histograms.push(newHistogram);

        this.setState({
            histograms,
        });
    };

    private deleteHistograms = () => {
        this.setState({
            histograms: undefined,
        });
    };

    private closeMutationModal = () => {
        this.setState({
            showMutationModal: false,
        });
    };

    private mutatePair = (
        histidine_kinase: string,
        response_regulator: string
    ): void => {
        const {pairs = []} = this.state;
        const pairsMap = this.adapter.createPairsMap(pairs);
        const pair = pairsMap.get(
            createMapKey(histidine_kinase, response_regulator)
        );

        this.setState({
            showMutationModal: true,
            mutationModalData: {
                msa: pair ? pair.msa : "",
                responseRegulator: response_regulator,
                histidineKinase: histidine_kinase,
                score: pair ? pair.score : 0,
            },
        });
    };

    private sortHeatmapUpperLeft(
        histidines: string[],
        regulators: string[],
        pairs: SelectablePair[]
    ): {regulators: string[]; histidines: string[]} {
        interface AnnotatedRegulator {
            name: string;
            importance: number;
        }

        const regulatorsWithImportance = regulators.map(
            (regulator: string): AnnotatedRegulator => {
                return {
                    name: regulator,
                    importance: pairs
                        .filter(pair => pair.response_regulator === regulator)
                        .map(pair => pair.score)
                        .reduce(function(accumulatedScore, currentScore) {
                            return accumulatedScore + currentScore;
                        }),
                };
            }
        );

        const sortedRegulators = regulatorsWithImportance
            .sort((a: AnnotatedRegulator, b: AnnotatedRegulator) =>
                a.importance > b.importance ? 1 : -1
            )
            .map(regulator => regulator.name);

        const sortedHistidines: string[] = [];
        sortedRegulators.forEach(regulator => {
            const availableHistidines = pairs
                .filter(pair => pair.response_regulator === regulator)
                .filter(
                    pair => !sortedHistidines.includes(pair.histidine_kinase)
                );
            if (availableHistidines.length > 0) {
                const bestMatch = availableHistidines.reduce(function(
                    previousMatch,
                    possibleMatch
                ) {
                    return previousMatch.score < possibleMatch.score
                        ? previousMatch
                        : possibleMatch;
                });
                sortedHistidines.push(bestMatch.histidine_kinase);
            }
        });

        histidines.forEach(histidine => {
            if (!sortedHistidines.includes(histidine)) {
                sortedHistidines.push(histidine);
            }
        });

        return {regulators: sortedRegulators, histidines: sortedHistidines};
    }

    private sortHeatmapAlphabetically(
        histidines: string[],
        regulators: string[]
    ): {regulators: string[]; histidines: string[]} {
        return {regulators: regulators.sort(), histidines: histidines.sort()};
    }

    private sortHeatmap(
        histidines: string[],
        regulators: string[],
        pairs: SelectablePair[],
        sortingAlgorithm: SortingAlgorithm
    ): {regulators: string[]; histidines: string[]} {
        switch (sortingAlgorithm) {
            case SortingAlgorithm.ALPHABETICAL:
                return this.sortHeatmapAlphabetically(histidines, regulators);
            case SortingAlgorithm.UPPERLEFT:
                return this.sortHeatmapUpperLeft(histidines, regulators, pairs);
            default:
                return this.sortHeatmapUpperLeft(histidines, regulators, pairs);
        }
    }

    private updateAdvancedSearchResults = (
        searchResults: ExtendedSelectablePair[]
    ): void => {
        this.setState({
            advancedSearchResults: searchResults,
            pairs: [],
        });
    };

    private showAdvancedSearchAsHeatmap = (): void => {
        const newPairs = [...this.state.advancedSearchResults];

        const histidines = Array.from(
            new Set(newPairs.map(pair => pair.histidine_kinase).sort())
        );
        const regulators = Array.from(
            new Set(newPairs.map(pair => pair.response_regulator).sort())
        );

        const sortedValues = this.sortHeatmap(
            histidines,
            regulators,
            newPairs,
            this.state.sortingAlgorithm
        );

        this.setState({
            pairs: newPairs,
            histidines: sortedValues.histidines,
            regulators: sortedValues.regulators,
            uniprot: newPairs[0].organism.uniprot,
            names: newPairs[0].organism.names,
            advancedSearchResults: [],
        });
    };

    private showSubselectionAsHeatmap = (): void => {
        const {
            pairs = [],
            histidines = [],
            regulators = [],
            heatmap = {} as NewHeatmapInterface,
        } = this.state;
        const newPairs = [...pairs.filter(pair => pair.selected)];

        let sortedValues: {regulators: string[]; histidines: string[]};

        if (this.state.fixedOrder) {
            const oldOrderHistidines = histidines.filter(
                label =>
                    [
                        ...pairs.filter(
                            pair =>
                                pair.histidine_kinase === label && pair.selected
                        ),
                    ].length > 0
            );
            const oldOrderRegulators = regulators.filter(
                label =>
                    [
                        ...pairs.filter(
                            pair =>
                                pair.response_regulator === label &&
                                pair.selected
                        ),
                    ].length > 0
            );
            sortedValues = {
                regulators: oldOrderRegulators,
                histidines: oldOrderHistidines,
            };
        } else {
            const histidines = Array.from(
                new Set(newPairs.map(pair => pair.histidine_kinase).sort())
            );
            const regulators = Array.from(
                new Set(newPairs.map(pair => pair.response_regulator).sort())
            );

            sortedValues = this.sortHeatmap(
                histidines,
                regulators,
                newPairs,
                this.state.sortingAlgorithm
            );
        }

        const updatedHeatmap: NewHeatmapInterface = {
            ...heatmap,
            histidineKinaseLabels: sortedValues.histidines,
            responseRegulatorLabels: sortedValues.regulators,
        };

        this.setState({
            pairs: newPairs.map(pair => {
                return {...pair, selected: false};
            }),
            histidines: sortedValues.histidines,
            regulators: sortedValues.regulators,
            heatmap: updatedHeatmap,
            advancedSearchResults: [],
        });
    };

    private setFixedOrder = (inputChecked: boolean): void => {
        this.setState({fixedOrder: inputChecked});
    };

    private setOverlaysBlocked = (inputChecked: boolean): void => {
        this.setState({overlaysBlocked: inputChecked});
    };

    private setSortingRadioButton = (
        id: string,
        inputNewState: boolean
    ): void => {
        let sorting: SortingAlgorithm;
        if (inputNewState) {
            switch (id) {
                case "upper-left-centered-order":
                    sorting = SortingAlgorithm.UPPERLEFT;
                    break;
                case "alphabetical-order":
                    sorting = SortingAlgorithm.ALPHABETICAL;
                    break;
                default:
                    sorting = SortingAlgorithm.UPPERLEFT;
            }
        } else {
            sorting = SortingAlgorithm.UPPERLEFT;
        }

        const {
            regulators = [],
            histidines = [],
            pairs = [],
            heatmap = {} as NewHeatmapInterface,
        } = this.state;
        const sortedLabels = this.sortHeatmap(
            histidines,
            regulators,
            pairs,
            sorting
        );

        const updatedHeatmap: NewHeatmapInterface = {
            ...heatmap,
            histidineKinaseLabels: sortedLabels.histidines,
            responseRegulatorLabels: sortedLabels.regulators,
        };

        this.setState({
            histidines: sortedLabels.histidines,
            regulators: sortedLabels.regulators,
            heatmap: updatedHeatmap,
            sortingAlgorithm: sorting,
        });
    };

    private setGradient = (gradient: string): void => {
        const {heatmap = {} as NewHeatmapInterface} = this.state;

        const updatedHeatmap: NewHeatmapInterface = {
            ...heatmap,
            gradient,
        };
        this.setState({heatmap: updatedHeatmap, gradient});
    };

    // *** Render ***

    public render(): JSX.Element {
        const {
            uniprot = "",
            names = "",
            histidines = [],
            regulators = [],
            pairs = [],
            gradient = "rgb(0,0,255) 0%, rgb(255,255,255) 33%, rgb(255,255,255) 66%, rgb(255,0,0) 100%",
        } = this.state;

        const heatmap: NewHeatmapInterface = {
            title: uniprot,
            uniprot: uniprot,
            names: names,
            pixelSize: 20,
            responseRegulatorLabels: regulators,
            histidineKinaseLabels: histidines,
            legendSelection: () => {},
            gradient: gradient,
        };

        const {
            mutationModalData = {
                msa: "",
                histidineKinase: "",
                responseRegulator: "",
                score: 0,
            } as MutationModalData,
        } = this.state;

        return (
            <MainPageComponent
                setHeatmapRef={this.setHeatmapRef}
                setPixelRef={this.setPixelRef}
                exportHeatmap={this.exportHeatmap}
                popHeatmap={this.popHeatmap}
                onDragBoxChange={this.onDragBoxChange}
                unselect={this.unselect}
                dropDownClickCallback={this.dropDownClickCallback}
                dropDownFilterChangeCallback={this.dropDownFilterChangeCallback}
                heatmap={heatmap}
                pairsList={pairs}
                heatmapOrganismList={this.state.heatmapOrganismList}
                toggleItem={this.togglePixel}
                addHistogram={this.addHistogram}
                histograms={this.state.histograms}
                deleteHistograms={this.deleteHistograms}
                closeMutationModal={this.closeMutationModal}
                mutationModalData={mutationModalData}
                processMutatedData={this.mutationDataReceive}
                showMutationModal={this.state.showMutationModal}
                mutatePair={this.mutatePair}
                organisms={this.state.organisms}
                advancedSearchResults={this.state.advancedSearchResults}
                updateAdvancedSearchResults={this.updateAdvancedSearchResults}
                showAdvancedSearchAsHeatmap={this.showAdvancedSearchAsHeatmap}
                showSubselectionAsHeatmap={this.showSubselectionAsHeatmap}
                fixedOrder={this.state.fixedOrder}
                overlaysBlocked={this.state.overlaysBlocked}
                sortingAlgorithm={this.state.sortingAlgorithm}
                setFixedOrder={this.setFixedOrder}
                setOverlaysBlocked={this.setOverlaysBlocked}
                setSortingRadioButton={this.setSortingRadioButton}
                setGradient={this.setGradient}
            />
        );
    }
}
