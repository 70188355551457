import React, {Component} from "react";
import {HeatmapLegendLabelComponent} from "./HeatmapLegendLabelComponent";
import "./HeatmapLegendStyle.css";

export type HeatMapLegendComponentProps = {
    gradient: string;
    numRow: number;
    pixelSize: number;
    maxVal: number;
    minVal: number;
    legendSelection: (threshold: number) => void;
};

type NonFunctionPropertyKeys<T> = {
    [P in keyof T]: T[P] extends Function ? never : P;
}[keyof T];

type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyKeys<T>>;

type HeatMapLegendStyleProps = NonFunctionProperties<
    HeatMapLegendComponentProps
>;

export class HeatMapLegend extends Component<HeatMapLegendComponentProps> {
    private readonly height: number;

    constructor(props: HeatMapLegendComponentProps) {
        super(props);
        this.height = 400;
    }

    getHeatMapLegendStyle(props: HeatMapLegendStyleProps): React.CSSProperties {
        return {
            display: "inline-block",
            marginLeft: "20px",
            width: props.pixelSize.toString() + "px",
            height: this.height.toString() + "px",
            background: `linear-gradient(180deg, ${props.gradient})`,
            border: "1px solid silver",
        };
    }

    render(): JSX.Element {
        return (
            <div className="heatmap-legend-wrapper">
                <div className="heatmap-legend-title">
                    H<sub>TCS</sub>
                    <sup>(specific)</sup>
                </div>
                <div className="heatmap-legend">
                    <div
                        className="heatmap-legend-bar"
                        style={this.getHeatMapLegendStyle({
                            ...this.props,
                        })}
                    />
                    <HeatmapLegendLabelComponent
                        labelNum={15}
                        minVal={this.props.minVal}
                        maxVal={this.props.maxVal}
                        pixelSize={this.props.pixelSize}
                        height={this.height + 23}
                        legendSelection={this.props.legendSelection}
                    />
                </div>
            </div>
        );
    }
}
