import Axios from "axios";
import {
    MutationRequestInterface,
    OrganismRequestInterface,
    PairRequestInterface,
    RequestInterface,
    SequenceRequestInterface,
    OrganismListRequestInterface,
    AdvancedSearchRequestInterface,
    generateEmptyStrRequest,
    generateEmptyRangeRequest,
} from "./RequestInterfaces";
import {
    MutationResponseInterface,
    OrganismResponseInterface,
    PairResponseInterface,
    ResponseInterface,
    SequenceResponseInterface,
    OrganismListResponseInterface,
} from "./ResponseInterfaces";

export enum Endpoints {
    Sequence = "sequence",
    Organism = "organism",
    Pair = "pair",
    Mutation = "mutation",
    PairList = "pairlist",
    AdvancedOrganism = "advOrganism",
}

type EndpointsMap = {
    sequence: SequenceEndpoint;
    organism: OrganismEndpoint;
    mutation: MutationEndpoint;
    pair: PairEndpoint;
    pairlist: PairListEndpoint;
};

type CreatedElement<T extends string> = T extends keyof EndpointsMap
    ? EndpointsMap[T] /** 1 **/
    : Endpoint<RequestInterface, ResponseInterface>;

/** 2 **/

export function APIFactory<T extends string>(
    endpointType: T,
    baseURL: string
): CreatedElement<T> {
    switch (endpointType) {
        case "organism":
            // @ts-ignore
            return new OrganismEndpoint(baseURL);
        case "organism-list":
            // @ts-ignore
            return new OrganismListEndpoint(baseURL);
        case "pair":
            // @ts-ignore
            return new PairEndpoint(baseURL);
        case "sequence":
            // @ts-ignore
            return new SequenceEndpoint(baseURL);
        case "mutation":
            // @ts-ignore
            return new MutationEndpoint(baseURL);
        case "pairlist":
            // @ts-ignore
            return new PairListEndpoint(baseURL);
        case "advOrganism":
            // @ts-ignore
            return new AdvancedOrganismEndpoint(baseURL);
        default:
            throw Error("Undefined Endpoint Type");
    }
}

export abstract class Endpoint<RequestInterface, ResponseType> {
    protected baseURL: string;

    protected constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    public abstract url_getter(paramList: RequestInterface): string;

    public request(
        paramList: RequestInterface
    ): Promise<ResponseType | void | undefined> {
        const url = this.url_getter(paramList);
        return this.makeRequest(url);
    }

    protected makeRequest(
        url: string
    ): Promise<ResponseType | void | undefined> {
        return Axios.get(url, {
            responseType: "json",
        })
            .then(response => {
                return response.data as ResponseType;
            })
            .catch(error => {
                console.error("Error Response");
                console.error(error);
            });
    }
}

export class OrganismEndpoint extends Endpoint<
    OrganismRequestInterface,
    OrganismResponseInterface[]
> {
    public constructor(baseURL: string) {
        super(baseURL);
    }

    url_getter(paramList: OrganismRequestInterface): string {
        return this.baseURL + "match/" + paramList.query;
    }
}

export class OrganismListEndpoint extends Endpoint<
    OrganismListRequestInterface,
    OrganismListResponseInterface[]
> {
    public constructor(baseURL: string) {
        super(baseURL);
    }

    url_getter(paramList: OrganismRequestInterface): string {
        return this.baseURL + "list";
    }
}

export class PairEndpoint extends Endpoint<
    PairRequestInterface,
    PairResponseInterface[]
> {
    public constructor(baseURL: string) {
        super(baseURL);
    }

    url_getter(paramList: PairRequestInterface): string {
        return this.baseURL + "pairs/" + paramList.id;
    }
}

export class SequenceEndpoint extends Endpoint<
    SequenceRequestInterface,
    SequenceResponseInterface
> {
    public constructor(baseURL: string) {
        super(baseURL);
    }

    url_getter(paramList: SequenceRequestInterface): string {
        return (
            this.baseURL +
            "sequence/" +
            paramList.response_regulator +
            "/" +
            paramList.histidine_kinase
        );
    }
}

export class MutationEndpoint extends Endpoint<
    MutationRequestInterface,
    MutationResponseInterface
> {
    public constructor(baseURL: string) {
        super(baseURL);
    }

    url_getter(paramList: MutationRequestInterface): string {
        throw Error("Not Implemented");
    }

    postRequest(
        paramList: MutationRequestInterface
    ): Promise<MutationResponseInterface | void | undefined> {
        return Axios.post(
            this.baseURL + "mutate",
            this.form_creator(paramList),
            {responseType: "json"}
        )
            .then(response => {
                return response.data as MutationResponseInterface;
            })
            .catch(error => {
                console.error("Error Response");
                console.error(error);
            });
    }

    form_creator(paramList: MutationRequestInterface): FormData {
        const form = new FormData();
        form.set("data", JSON.stringify(paramList));
        return form;
    }

    request(
        paramList: MutationRequestInterface
    ): Promise<void | MutationResponseInterface | undefined> {
        throw Error("Not Implemented");
    }
}

export class PairListEndpoint extends Endpoint<
    AdvancedSearchRequestInterface,
    PairResponseInterface[]
> {
    public constructor(baseURL: string) {
        super(baseURL);
    }

    postRequest(
        paramList: AdvancedSearchRequestInterface
    ): Promise<PairResponseInterface[] | void | undefined> {
        return Axios.post(
            this.baseURL + "pairlist",
            this.form_creator(paramList),
            {
                responseType: "json",
            }
        )
            .then(response => {
                return response.data as PairResponseInterface[];
            })
            .catch(error => {
                console.error(error);
            });
    }

    form_creator(paramList: AdvancedSearchRequestInterface): FormData {
        const form = new FormData();
        const val = {
            requestByRange: paramList.requestByRange
                ? paramList.requestByRange
                : generateEmptyRangeRequest(),
            requestByOrganism: paramList.requestByOrganism
                ? paramList.requestByOrganism
                : generateEmptyStrRequest(),
            requestByHK: paramList.requestByHK
                ? paramList.requestByHK
                : generateEmptyStrRequest(),
            requestByRR: paramList.requestByRR
                ? paramList.requestByRR
                : generateEmptyStrRequest(),
            requestByMSA: paramList.requestByMSA
                ? paramList.requestByMSA
                : generateEmptyStrRequest(),
            organismID: paramList.organismID,
            resultCount: paramList.resultCount,
            pageNumber: paramList.pageNumber,
        };
        form.set("data", JSON.stringify(val));
        return form;
    }

    request(
        paramList: AdvancedSearchRequestInterface
    ): Promise<void | PairResponseInterface[] | undefined> {
        throw Error("Not implemented");
    }

    url_getter(paramList: AdvancedSearchRequestInterface): string {
        throw Error("Not implemented");
    }
}
