import React, {Component} from "react";
import {ColorPlace} from "./MutationContainerComponent";

interface MutationBackgroundComponentProps {
    rowCount: number;
    numCharInLine: number;
    colorPlace: ColorPlace[];
    charOffset: number;
    charDiff: number;
}

export class MutationBackgroundComponent extends Component<
    MutationBackgroundComponentProps
> {
    private letterSpacing: number = 4.45;
    private marginBottom: number = 26;
    private fontSize: number = 10;
    private width: number = 14.4 * this.props.charDiff;

    private renderLabelDiv(index: number): JSX.Element {
        return (
            <div
                className={"mutation-letter-label"}
                style={{
                    width: this.width.toString() + "px",
                    fontSize: this.fontSize.toString(10) + "px",
                    lineHeight: this.fontSize.toString(10) + "px",
                    background: "rgba(0, 0, 0 ,0)",
                    display: "inline-block",
                    marginBottom: this.marginBottom.toString(10) + "px",
                    textAlign: "right",
                    fontFamily: "Open Sans",
                    flexGrow: 0,
                    flexShrink: 0,
                }}
            >
                {this.props.charOffset + this.props.charDiff * (index + 1)}
            </div>
        );
    }

    private getNumberOfLabels = (): number => {
        return (
            (this.props.numCharInLine / this.props.charDiff) *
            this.props.rowCount
        );
    };

    public render(): JSX.Element {
        return (
            <div
                className={"mutation-background-wrapper"}
                style={{
                    marginTop: "4px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "450px",
                    height: "100%",
                    alignContent: "flex-start",
                }}
            >
                {Array(this.getNumberOfLabels())
                    .fill("x")
                    .map((item, index) => this.renderLabelDiv(index))}
            </div>
        );
    }
}
