import React, {Component} from "react";
import {AdvancedSearchContainerState} from "./AdvancedSearchContainer";
import {Accordion, Button, Card, Form, InputGroup} from "react-bootstrap";
import {QueryMode} from "../../pages/MainPage/MainPageContainer";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./AdvancedSearchStyle.css";

interface AdvancedSearchComponentProps {
    parentState: AdvancedSearchContainerState;
    searchClick: () => void;
    pageChangeClick: (pageDiff: number) => void;
    inputChange: (value: any, name: string, category?: string) => void;
    handleRadioButtonChange: (
        parameter: string,
        value: QueryMode,
        inputNewState: boolean
    ) => void;
    clearResults: () => void;
    loading: boolean;
    searchError: boolean;
}

export class AdvancedSearchComponent extends Component<
    AdvancedSearchComponentProps,
    {}
> {
    handleInputChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const idx = target.name.indexOf(".");
        if (idx !== -1) {
            const name = target.name.substr(0, target.name.indexOf("."));
            const category = target.name.substr(target.name.indexOf(".") + 1);
            this.props.inputChange(value, name, category);
        } else {
            this.props.inputChange(value, target.name);
        }
    };

    private handleRadioButtonChange = (
        parameter: string,
        queryMode: QueryMode,
        checked: boolean
    ): void => {
        this.props.handleRadioButtonChange(parameter, queryMode, checked);
    };

    public render(): JSX.Element {
        return (
            <>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Advanced Search
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>
                                            <span
                                                className={"adv-search-label"}
                                            >
                                                {" "}
                                                Organism &nbsp;{" "}
                                            </span>
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .organismQueryMode ===
                                                    QueryMode.CONTAINS
                                                }
                                                type={"radio"}
                                                label={`Contains.`}
                                                id={"organism-query-contains"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "organismQueryMode",
                                                        QueryMode.CONTAINS,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .organismQueryMode ===
                                                    QueryMode.START
                                                }
                                                type={"radio"}
                                                label={`Starts with.`}
                                                id={"organism-query-start"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "organismQueryMode",
                                                        QueryMode.START,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .organismQueryMode ===
                                                    QueryMode.END
                                                }
                                                type={"radio"}
                                                label={`Ends with.`}
                                                id={"organism-query-ends"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "organismQueryMode",
                                                        QueryMode.END,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .organismQueryMode ===
                                                    QueryMode.MATCHES
                                                }
                                                type={"radio"}
                                                label={`Matches.`}
                                                id={"organism-query-matches"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "organismQueryMode",
                                                        QueryMode.MATCHES,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </Form.Label>

                                        <Form.Control
                                            type="text"
                                            name={"organismQuery"}
                                            value={
                                                this.props.parentState
                                                    .organismQuery
                                            }
                                            onChange={this.handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>
                                            <span
                                                className={"adv-search-label"}
                                            >
                                                {" "}
                                                Histidine Kinase &nbsp;{" "}
                                            </span>
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .hkQueryMode ===
                                                    QueryMode.CONTAINS
                                                }
                                                type={"radio"}
                                                label={`Contains.`}
                                                id={"hk-query-contains"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "hkQueryMode",
                                                        QueryMode.CONTAINS,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .hkQueryMode ===
                                                    QueryMode.START
                                                }
                                                type={"radio"}
                                                label={`Starts with.`}
                                                id={"hk-query-start"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "hkQueryMode",
                                                        QueryMode.START,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .hkQueryMode ===
                                                    QueryMode.END
                                                }
                                                type={"radio"}
                                                label={`Ends with.`}
                                                id={"hk-query-ends"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "hkQueryMode",
                                                        QueryMode.END,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .hkQueryMode ===
                                                    QueryMode.MATCHES
                                                }
                                                type={"radio"}
                                                label={`Matches.`}
                                                id={"hk-query-matches"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "hkQueryMode",
                                                        QueryMode.MATCHES,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={"hkQuery"}
                                            value={
                                                this.props.parentState.hkQuery
                                            }
                                            onChange={this.handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>
                                            <span
                                                className={"adv-search-label"}
                                            >
                                                {" "}
                                                Response Regulator &nbsp;
                                            </span>
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .rrQueryMode ===
                                                    QueryMode.CONTAINS
                                                }
                                                type={"radio"}
                                                label={`Contains.`}
                                                id={"rr-query-contains"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "rrQueryMode",
                                                        QueryMode.CONTAINS,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .rrQueryMode ===
                                                    QueryMode.START
                                                }
                                                type={"radio"}
                                                label={`Starts with.`}
                                                id={"rr-query-start"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "rrQueryMode",
                                                        QueryMode.START,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .rrQueryMode ===
                                                    QueryMode.END
                                                }
                                                type={"radio"}
                                                label={`Ends with.`}
                                                id={"rr-query-ends"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "rrQueryMode",
                                                        QueryMode.END,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                inline
                                                checked={
                                                    this.props.parentState
                                                        .rrQueryMode ===
                                                    QueryMode.MATCHES
                                                }
                                                type={"radio"}
                                                label={`Matches.`}
                                                id={"rr-query-matches"}
                                                onChange={(
                                                    event: React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                ) =>
                                                    this.handleRadioButtonChange(
                                                        "rrQueryMode",
                                                        QueryMode.MATCHES,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                name={"rrQuery"}
                                                value={
                                                    this.props.parentState
                                                        .rrQuery
                                                }
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />

                                            {this.props.searchError ? (
                                                <Form.Control.Feedback type="invalid">
                                                    At least one field should
                                                    not be empty.
                                                </Form.Control.Feedback>
                                            ) : (
                                                undefined
                                            )}
                                        </InputGroup>
                                    </Form.Group>

                                    <Accordion>
                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey="0"
                                            >
                                                More Options
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <span
                                                                className={
                                                                    "adv-search-label"
                                                                }
                                                            >
                                                                {" "}
                                                                MSA &nbsp;{" "}
                                                            </span>
                                                            <Form.Check
                                                                inline
                                                                checked={
                                                                    this.props
                                                                        .parentState
                                                                        .msaQueryMode ===
                                                                    QueryMode.CONTAINS
                                                                }
                                                                type={"radio"}
                                                                label={`Contains.`}
                                                                id={
                                                                    "msa-query-contains"
                                                                }
                                                                onChange={(
                                                                    event: React.ChangeEvent<
                                                                        HTMLInputElement
                                                                    >
                                                                ) =>
                                                                    this.handleRadioButtonChange(
                                                                        "msaQueryMode",
                                                                        QueryMode.CONTAINS,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                            <Form.Check
                                                                inline
                                                                checked={
                                                                    this.props
                                                                        .parentState
                                                                        .msaQueryMode ===
                                                                    QueryMode.START
                                                                }
                                                                type={"radio"}
                                                                label={`Starts with.`}
                                                                id={
                                                                    "msa-query-start"
                                                                }
                                                                onChange={(
                                                                    event: React.ChangeEvent<
                                                                        HTMLInputElement
                                                                    >
                                                                ) =>
                                                                    this.handleRadioButtonChange(
                                                                        "msaQueryMode",
                                                                        QueryMode.START,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                            <Form.Check
                                                                inline
                                                                checked={
                                                                    this.props
                                                                        .parentState
                                                                        .msaQueryMode ===
                                                                    QueryMode.END
                                                                }
                                                                type={"radio"}
                                                                label={`Ends with.`}
                                                                id={
                                                                    "msa-query-ends"
                                                                }
                                                                onChange={(
                                                                    event: React.ChangeEvent<
                                                                        HTMLInputElement
                                                                    >
                                                                ) =>
                                                                    this.handleRadioButtonChange(
                                                                        "msaQueryMode",
                                                                        QueryMode.END,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                            <Form.Check
                                                                inline
                                                                checked={
                                                                    this.props
                                                                        .parentState
                                                                        .msaQueryMode ===
                                                                    QueryMode.MATCHES
                                                                }
                                                                type={"radio"}
                                                                label={`Matches.`}
                                                                id={
                                                                    "msa-query-matches"
                                                                }
                                                                onChange={(
                                                                    event: React.ChangeEvent<
                                                                        HTMLInputElement
                                                                    >
                                                                ) =>
                                                                    this.handleRadioButtonChange(
                                                                        "msaQueryMode",
                                                                        QueryMode.MATCHES,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name={"msaQuery"}
                                                            value={
                                                                this.props
                                                                    .parentState
                                                                    .msaQuery
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label>
                                                            Specificity at
                                                            least:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name={"upperBound"}
                                                            pattern="-?\d*\.?\d*"
                                                            value={
                                                                this.props
                                                                    .parentState
                                                                    .upperBound
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label>
                                                            Specificity less
                                                            than:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name={"lowerBound"}
                                                            pattern="-?\d*\.?\d*"
                                                            value={
                                                                this.props
                                                                    .parentState
                                                                    .lowerBound
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label>
                                                            Max Result Count:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name={"resultCount"}
                                                            pattern="\d*"
                                                            value={this.props.parentState.resultCount.toString()}
                                                            onChange={
                                                                this
                                                                    .handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <br />

                                    {this.props.loading ? (
                                        <Button variant="primary" disabled>
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                size={"lg"}
                                                spin
                                            />{" "}
                                            Loading
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="primary"
                                            onClick={this.props.searchClick}
                                        >
                                            Search
                                        </Button>
                                    )}
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <br />
                <br />
            </>
        );
    }
}
