import React, {Component} from "react";

interface mutationBoxComponentProps {
    value: string;
    fontSize: number;
    letterSpacing: number;
    onChange: (val: string) => void;
    rowCount: number;
}

export class MutationTextBoxComponent extends Component<
    mutationBoxComponentProps
> {
    private handleChange = (event: any) => {
        this.props.onChange(event.target.value);
    };

    render(): JSX.Element {
        return (
            <textarea
                style={{
                    width: (440).toString() + "px",
                    fontSize: this.props.fontSize.toString() + "px",
                    fontFamily: '"Lucida Console", Monaco, monospace',
                    letterSpacing: this.props.letterSpacing.toString() + "px",
                    marginTop: (8).toString() + "px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    backgroundColor: "transparent",
                    lineHeight: (this.props.fontSize + 16).toString() + "px",
                    overflow: "hidden",
                    wordBreak: "break-all",
                    resize: "none",
                    borderStyle: "none",
                    borderColor: "transparent",
                    padding: "0px",
                }}
                rows={this.props.rowCount}
                spellCheck={false}
                onChange={this.handleChange}
                value={this.props.value}
            />
        );
    }
}
