import React, {Component} from "react";
import HBarContainerComponent from "./HBarContainerComponent";

export interface HistogramComponentProps {
    data: Array<number>;
    labels: Array<string>;
    title: string;
    width: number;
    height: number;
    gradient: string;
    maxValue: number;
    minValue: number;
    organism: string;
    labelBottom: string;
}

export default class HistogramComponent extends Component<
    HistogramComponentProps
> {
    private barWidth: number;
    private barMaxHeight: number;

    constructor(props: HistogramComponentProps) {
        super(props);
        this.barWidth = this.props.width / this.props.data.length;
        this.barMaxHeight = this.props.height * 0.95;
    }

    public render(): JSX.Element {
        return (
            <div className={"histogram-bars-container"}>
                <HBarContainerComponent
                    gradient={this.props.gradient}
                    data={this.props.data}
                    labels={this.props.labels}
                    barWidth={20}
                    barMaxHeight={100}
                    key={this.props.title}
                />
            </div>
        );
    }
}
