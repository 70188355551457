import React, {Component} from "react";
import {
    Accordion,
    Button,
    Card,
    Form,
    FormLabel,
    InputGroup,
} from "react-bootstrap";
import {SortingAlgorithm} from "../../pages/MainPage/MainPageContainer";
import "./HeatmapSettingsBarStyle.css";

interface HeatmapSettingsBarProps {
    alphabeticalOrder: boolean;
    upperLeftCenteredOrder: boolean;
    fixedOrder: boolean;
    overlaysBlocked: boolean;
    sortingAlgorithm: SortingAlgorithm;
    setFixedOrder: (inputChecked: boolean) => void;
    setOverlaysBlocked: (inputChecked: boolean) => void;
    setSortingRadioButton: (id: string, inputChecked: boolean) => void;
    setGradient: (gradient: string) => void;
}

interface HeatmapSettingsBarState {
    gradient: string;
    gradientError: boolean;
}

export class HeatmapSettingsBar extends Component<
    HeatmapSettingsBarProps,
    HeatmapSettingsBarState
> {
    constructor(props: HeatmapSettingsBarProps) {
        super(props);
        this.state = {
            gradient:
                "rgb(0,0,255) 0%, rgb(255,255,255) 33%, rgb(255,255,255) 66%,rgb(255,0,0) 100%",
            gradientError: false,
        };
    }

    private setFixedOrder = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (event.target.type === "checkbox") {
            const checked: boolean = event.target.checked;
            this.props.setFixedOrder(checked);
        } else {
            console.error("Callback only works for input type checkbox");
        }
    };

    private setOverlaysBlocked = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (event.target.type === "checkbox") {
            const checked: boolean = event.target.checked;
            this.props.setOverlaysBlocked(checked);
        } else {
            console.error("Callback only works for input type checkbox");
        }
    };

    private setSortingRadioButton = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (event.target.type === "radio") {
            const checked: boolean = event.target.checked;
            const inputID: string = event.target.id;
            this.props.setSortingRadioButton(inputID, checked);
        } else {
            console.error("Callback only works for input type radio");
        }
    };

    private updateGradient = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (event.target.type === "text") {
            const value: string = event.target.value;
            this.setState({gradient: value});
        } else {
            console.error("Callback only works for input type text");
        }
    };

    private setGradient = (event: React.MouseEvent): void => {
        const {gradient = ""} = this.state;

        if (
            /^rgb\(([0-9]{1,3},){1,2}[0-9]{1,3}\)\s?0%(,\s?rgb\(([0-9]{1,3},){1,2}[0-9]{1,3}\)\s?([0-9]|[1-9][0-9])%)*,\s?rgb\(([0-9]{1,3},){1,2}[0-9]{1,3}\)\s?100%$/.test(
                gradient
            )
        ) {
            this.props.setGradient(gradient);
            this.setState({gradientError: false});
        } else {
            this.setState({gradientError: true});
            console.error("Gradient does not match correct pattern");
        }
    };

    public render(): JSX.Element {
        return (
            <>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Heatmap Display Settings
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Form>
                                    <Form.Group>
                                        <FormLabel>
                                            Sorting Algorithm:
                                        </FormLabel>
                                        <Form.Check
                                            checked={
                                                this.props.sortingAlgorithm ===
                                                SortingAlgorithm.UPPERLEFT
                                            }
                                            type={"radio"}
                                            label={`Best available match on diagonal.`}
                                            id={"upper-left-centered-order"}
                                            onChange={
                                                this.setSortingRadioButton
                                            }
                                        />
                                        <Form.Check
                                            checked={
                                                this.props.sortingAlgorithm ===
                                                SortingAlgorithm.ALPHABETICAL
                                            }
                                            type={"radio"}
                                            label={`Alphabetically ordered.`}
                                            id={"alphabetical-order"}
                                            onChange={
                                                this.setSortingRadioButton
                                            }
                                        />
                                    </Form.Group>

                                    <hr></hr>
                                    <Form.Group>
                                        <Form.Check
                                            checked={this.props.fixedOrder}
                                            custom
                                            type="switch"
                                            id="custom-switch"
                                            label="Preserve order of items in subselections."
                                            onChange={this.setFixedOrder}
                                        />
                                    </Form.Group>
                                    <hr />
                                    <Form.Group>
                                        <Form.Label>Gradient:</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                isInvalid={
                                                    this.state.gradientError
                                                }
                                                type="text"
                                                placeholder="Enter gradient"
                                                defaultValue={
                                                    "rgb(0,0,255) 0%, rgb(255,255,255) 33%, rgb(255,255,255) 66%, rgb(255,0,0) 100%"
                                                }
                                                onChange={this.updateGradient}
                                            />
                                            <InputGroup.Append>
                                                <Button
                                                    variant={"primary"}
                                                    id="update-gradient"
                                                    onClick={this.setGradient}
                                                >
                                                    Update Gradient
                                                </Button>
                                            </InputGroup.Append>
                                            {this.state.gradientError ? (
                                                <Form.Control.Feedback type="invalid">
                                                    Gradient string is
                                                    incorrect!
                                                </Form.Control.Feedback>
                                            ) : (
                                                undefined
                                            )}
                                        </InputGroup>
                                        <Form.Text>
                                            You can provide a{" "}
                                            <b>custom color map</b>&nbsp; for
                                            your heatmap legend. It follows the
                                            rules for <b>CSS color gradients</b>
                                            . The color map has to start with a
                                            color in rgb format (i.e.{" "}
                                            <i>rgb(255,0,0)</i> for the color{" "}
                                            <i>red</i>) and 0% for the position
                                            of the first stop. The only other
                                            requirement is a final stop at 100%.
                                            You can add as many stops in between
                                            as you would like to, colors are
                                            interpolated linearly between stops.
                                        </Form.Text>
                                    </Form.Group>
                                    <hr />
                                    <Form.Group>
                                        <Form.Label>Overlays:</Form.Label>
                                        <Form.Check
                                            checked={this.props.overlaysBlocked}
                                            custom
                                            type="switch"
                                            id="overlays-switch"
                                            label="Temporarily block overlays from appearing on hover."
                                            onChange={this.setOverlaysBlocked}
                                        />
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <br />
                <br />
            </>
        );
    }
}
