import {HeaderComponent} from "../MainPage/MainMenu/HeaderComponent";
import React from "react";
import {Col, Container, Image, Nav, Row} from "react-bootstrap";
import {useWindowSize} from "../../hooks/WindowSizeHook";
import {PageMode} from "../../hooks/usePageModeHook";
import {usePageMode} from "../../providers/PageModeProvider";

export function TutorialPageComponent() {
    const size = useWindowSize();

    const {pageState, dispatch} = usePageMode();
    const {pageMode = PageMode.LARGESCREEN} = pageState;

    const fullHeight = 770;

    const renderFooterPanel = (): JSX.Element => {
        return (
            <>
                <a href={"https://www.utdallas.edu/"}>
                    <Image
                        src="./images/UT_Dallas_black_transp.png"
                        style={{
                            position: "fixed",
                            bottom: "220px",
                            left: "2em",
                            width: "160px",
                        }}
                    />
                </a>
                <a href={"http://morcoslaboratory.org"}>
                    <Image
                        src="./images/EVI_logoSquare.png"
                        style={{
                            position: "fixed",
                            bottom: "2em",
                            width: "160px",
                            left: "2em",
                        }}
                    />
                </a>
            </>
        );
    };

    const renderFooterPanel2 = (): JSX.Element => {
        return (
            <div
                style={{
                    borderTop: "1px solid #ececec",
                    backgroundColor: "#f7f7f7",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <a href={"https://www.utdallas.edu/"}>
                    <Image
                        src="./images/UT_Dallas_black_transp.png"
                        style={{
                            width: "200px",
                            margin: "50px 0px 50px 0px",
                        }}
                    />
                </a>
                <a href={"http://morcoslaboratory.org"}>
                    <Image
                        src="./images/EVI_logoSquare.png"
                        style={{
                            width: "200px",
                            margin: "0px 0px 50px 0px",
                        }}
                    />
                </a>
            </div>
        );
    };

    function render(): JSX.Element {
        let menuPanelStyle: any = {};
        let menuColumnStyle: any = {};
        const {width = 0, height = 0} = size;
        if (pageMode !== PageMode.MOBILE) {
            menuPanelStyle = {
                /*position: "fixed",
                top: "1em",*/
            };

            menuColumnStyle = {
                minHeight: "100vh",
                borderRight: "1px solid #ececec",
                backgroundColor: "#f7f7f7",
                position: "relative",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "flex",
                flex: "0 0 300px",
                width: "300px",
            };
        } else {
            menuColumnStyle = {
                borderRight: "1px solid #ececec",
                borderBottom: "1px solid #ececec",
                backgroundColor: "#f7f7f7",
                width: "100%",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "flex",
                flex: "0 0 100%",
                paddingBottom: "20px",
            };

            menuPanelStyle = {
                width: "100%",
            };
        }

        const renderLeftPanel = (): JSX.Element => {
            return (
                <div style={menuColumnStyle}>
                    <div style={menuPanelStyle}>
                        <HeaderComponent />

                        <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link href="#layout">Layout</Nav.Link>
                            <Nav.Link href="#search">Search</Nav.Link>
                            <Nav.Link href="#heatmaps">Heatmaps</Nav.Link>
                            <Nav.Link href="#selections">Selections</Nav.Link>
                            <Nav.Link href="#mutations">Mutations</Nav.Link>
                            <Nav.Link href="#export">Export</Nav.Link>
                        </Nav>

                        {pageMode !== PageMode.MOBILE && height > fullHeight
                            ? renderFooterPanel()
                            : undefined}
                    </div>
                </div>
            );
        };

        return (
            <div className={"main-page"}>
                <div id={"layout"} className={"main-page-content"}>
                    <Container fluid>
                        <Row style={{minHeight: "100vh"}}>
                            {renderLeftPanel()}
                            <Col xs={12} sm={10} md={7} lg={5}>
                                <div style={{width: "100%", height: "2em"}} />
                                <h1>
                                    <b>E</b>volutionary <b>L</b>inks <b>I</b>
                                    nferred for <b>H</b>istidine <b>K</b>inase{" "}
                                    <b>S</b>ensors <b>I</b>nteracting with{" "}
                                    <b>R</b>esponse regulators
                                </h1>
                                <div style={{width: "100%", height: "2em"}} />
                                <h2>Layout</h2>
                                <p>
                                    The general layout of the main page of
                                    ELIHKSIR can be divided into three parts -
                                    the left panel, the center, and the right
                                    panel.
                                </p>
                                <h2 id={"search"}>Search</h2>
                                <p>
                                    On the left panel of the main page, the user
                                    can use the text box labeled ‘Select an
                                    Organism’ to input a query that returns all
                                    matching organisms by their name or UNIPROT
                                    mnemonic identifier. By clicking on one of
                                    the returned rows, the response regulator
                                    and histidine kinase data for the
                                    corresponding organism will be downloaded
                                    and displayed in the center of the main page
                                    ready for further analysis.
                                </p>
                                <Image
                                    src="./tutorialData/search.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                <h2 id={"heatmaps"}>Heatmaps</h2>
                                <p>
                                    The default means of visualization for the
                                    downloaded organism data is via a heatmap,
                                    which will be automatically displayed once
                                    the user selects an organism from the search
                                    results.{" "}
                                </p>
                                <p>
                                    Each row corresponds to a{" "}
                                    <b>histidine kinase (rows)</b> and each
                                    column gives the Hamiltonian score towards a{" "}
                                    <b>response regulator (columns)</b>
                                </p>

                                <p>
                                    More negative hamiltonian scores indicate a
                                    more specific interaction between two
                                    partners , and more positive values indicate
                                    a weaker specificity. The user can associate
                                    the colors to specific values via the
                                    increments on the legend displayed on the
                                    right of the heatmap.
                                </p>
                                <p>
                                    In the heatmap, the user can open a
                                    histogram visualization for response
                                    regulators (columns) and a histidine kinase
                                    (rows) by clicking on the names of a
                                    histidine kinase.
                                </p>
                                <Image
                                    src="./tutorialData/histogram.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                <h2 id={"selections"}>Selections</h2>
                                <p>
                                    The user can create a selection of specific
                                    response regulator and histidine kinase
                                    predictions by clicking and dragging in a
                                    box, clicking on the individual cells, and
                                    clicking on the labels on the legend.
                                </p>
                                <p>
                                    Clicking and dragging will generate a box on
                                    the screen in which all heatmap cells
                                    located inside that box will be selected.
                                    This method is not a toggle and will not
                                    unselect already selected cells.
                                </p>
                                <Image
                                    src="./tutorialData/selection-by-drag.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                <p>
                                    Clicking on the individual cells will toggle
                                    the selection of the corresponding cells.
                                </p>
                                <Image
                                    src="./tutorialData/selection-by-click.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                {/*<p>
                                    Clicking on the labels on the legend which
                                    has numeric values associated with them will
                                    select all cells with values less than the
                                    associated values on the label. The reason
                                    it selects all cells with values less is
                                    because lower hamiltonian scores indicate a
                                    stronger prediction.
                                </p>
                                <Image
                                    src="./tutorialData/selection-by-legend.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />*/}
                                <p>
                                    Finally, on the right panel of the main
                                    page, when one or more selections have been
                                    made, the user can click the unselect button
                                    to clear all selections.
                                </p>
                                <Image
                                    src="./tutorialData/unselect.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                {/* <h2 id={"graphs"}>Graphs</h2>
                                <p>
                                    When one or more cells(response regulator
                                    and histidine kinase predictions) is
                                    selected on the heatmap, a network graph
                                    will be displayed on the upper portion of
                                    the right panel.
                                </p>
                                <p>
                                    The nodes represent the response regulator
                                    or the histidine kinase of the selected
                                    predictions. The user can hover over each
                                    node to see their names.
                                </p>
                                <p>
                                    The edges represent the strength of the
                                    prediction between the response regulator
                                    and the histidine kinase. Lower the
                                    hamiltonian scores, darker the edges will be
                                    displayed because the strength of the
                                    prediction is inversely proportional to the
                                    hamiltonian scores.
                                </p>
                                <Image
                                    src="./tutorialData/graphs.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />*/}
                                <h2 id={"mutations"}>Mutations</h2>
                                <p>
                                    While a heatmap is being displayed on the
                                    main page, the user may right click on a
                                    cell to open a new interface for mutating
                                    the residue sequence between the
                                    corresponding response regulator and
                                    histidine kinase.
                                </p>
                                <p>
                                    The interface will display the name of the
                                    response regulator, name of the histidine
                                    kinase, the original hamiltonian score, and
                                    the text box that contains the original
                                    residue sequence.
                                </p>
                                <p>
                                    The user may modify the content of the
                                    residue sequence. After attaining the
                                    desired modifications, the user may evaluate
                                    the new hamiltonian score for the pair based
                                    on the modified residue sequence by clicking
                                    on the Evaluate button.{" "}
                                </p>
                                <p>
                                    This process will associate a new
                                    hamiltonian score for that pair until the
                                    user resets the heatmap by pressing the
                                    reset button in the left panel or by
                                    choosing a new organism.
                                </p>
                                {/*                                <p>
                                    This will affect the representation of the
                                    mutated pair in its associated heatmap,
                                    histogram, and the graph.
                                </p>*/}
                                <Image
                                    src="./tutorialData/mutate.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />

                                <h2 id={"export"}>Export</h2>
                                <p>
                                    The user has three options of externally
                                    exporting the data from ELIHKSIR.
                                </p>
                                <p>
                                    First, the user may export a PNG image of
                                    the entirety of the heatmap as a png by
                                    clicking on the Export to PNG button on the
                                    left panel once a heatmap has been
                                    displayed. This will generate a PNG image of
                                    the heatmap on a transparent background and
                                    download it on to the user’s machine. The
                                    image will also include the labels, the
                                    legend, and any open histograms of the
                                    corresponding heatmap.{" "}
                                </p>
                                <p>
                                    Second, the user may export a PNG image of a
                                    histogram of a row of response regulator and
                                    histidine kinase pairs that corresponds to a
                                    desired histidine kinase by clicking on the
                                    Export to PNG button that is located inside
                                    the opened histogram. The histogram export
                                    will also include the names of each response
                                    regulator.
                                </p>
                                <Image
                                    src="./tutorialData/export-png.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                <p>
                                    Finally, the user may export a CSV
                                    representation of user’s arbitrary
                                    selections of the cells of the heatmap.
                                    After the user makes selections of the cells
                                    on the heatmap, the Export to CSV button on
                                    the right panel can be clicked to download a
                                    file that contains a comma delimited list of
                                    the user’s selections.
                                </p>
                                <p>
                                    For all these methods of exporting, all of
                                    the methods will take into consideration of
                                    the mutated hamiltonian values(if any) of
                                    the response regulator and histidine kinase
                                    pairs.{" "}
                                </p>

                                <Image
                                    src="./tutorialData/exportfile.gif"
                                    style={{width: "600px"}}
                                    thumbnail
                                />
                                <div style={{height: "5em"}} />
                            </Col>

                            {pageMode === PageMode.MOBILE
                                ? renderFooterPanel2()
                                : undefined}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }

    return render();
}
