import React, {Component} from "react";

interface MutationBackgroundHighlightComponentProps {
    value: string[];
}

export class MutationBackgroundHighlightComponent extends Component<
    MutationBackgroundHighlightComponentProps
> {
    private marginTop: number = 12;
    private marginBottom: number = 2;
    private width: number = 14.5;

    private renderHighlightDiv = (color: string): JSX.Element => {
        return (
            <div
                className={"mutation-letter-label"}
                style={{
                    width: this.width.toString() + "px",
                    height: "22px",
                    backgroundColor: `${color}`,
                    opacity: "0.5",
                    display: "inline-block",
                    marginTop: this.marginTop.toString(10) + "px",
                    marginBottom: this.marginBottom.toString(10) + "px",
                    flexGrow: 0,
                    flexShrink: 0,
                }}
            />
        );
    };

    public render(): JSX.Element {
        return (
            <div
                className={"mutation-background-wrapper"}
                style={{
                    marginTop: "4px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "440px",
                    height: "100%",
                    alignContent: "flex-start",
                }}
            >
                {this.props.value.map(color => this.renderHighlightDiv(color))}
            </div>
        );
    }
}
