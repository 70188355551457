import {QueryMode} from "../pages/MainPage/MainPageContainer";

export interface RequestInterface {}

export interface PairRequestInterface extends RequestInterface {
    id: number;
}

export interface OrganismRequestInterface extends RequestInterface {
    query: string;
}

export interface OrganismListRequestInterface extends RequestInterface {}

export interface SequenceRequestInterface extends RequestInterface {
    response_regulator: string;
    histidine_kinase: string;
}

export interface MultipleSequenceAlignment {
    responseRegulator: string;
    histidineKinase: string;
    msa: string;
}

export interface MutationRequestInterface extends RequestInterface {
    query: MultipleSequenceAlignment[];
}

export interface AdvancedSearchRequestInterface extends RequestInterface {
    requestByRange?: rangeRequestMethods;
    requestByOrganism?: strRequestMethods;
    requestByHK?: strRequestMethods;
    requestByRR?: strRequestMethods;
    requestByMSA?: strRequestMethods;
    organismID: number;
    resultCount: number;
    pageNumber: number;
}

interface strRequestMethods {
    query: string;
    queryMode: QueryMode;
}

interface rangeRequestMethods {
    upperBound: string;
    lowerBound: string;
}

export function generateEmptyStrRequest(): strRequestMethods {
    return {
        query: "",
        queryMode: QueryMode.CONTAINS,
    };
}

export function generateEmptyRangeRequest(): rangeRequestMethods {
    return {
        lowerBound: "",
        upperBound: "",
    };
}
