import {BasicPairInterface} from "./ResponseInterfaces";

interface selection {
    selected: boolean;
}

export type SelectablePair = selection & BasicPairInterface;

export function createMapKey(
    histidine_kinase: string,
    response_regulator: string
): string {
    return `${histidine_kinase}-${response_regulator}`;
}
export function createMatrixArrayfromMap<ItemType, ReturnType extends ItemType>(
    histidines: Array<string>,
    regulators: Array<string>,
    originalRaw: Map<string, ItemType>
): Array<Array<ReturnType>> {
    const result = new Array<Array<ReturnType>>();

    for (let y = 0; y < regulators.length; y++) {
        let temp = [];
        for (let x = 0; x < histidines.length; x++) {
            temp.push(
                originalRaw.get(
                    createMapKey(histidines[x], regulators[y])
                ) as ReturnType
            );
        }
        result.push(temp);
    }
    return result;
}

export function returnColNoDuplicates(
    target: BasicPairInterface[],
    colNum: number
): Array<any> {
    if (colNum === 0) {
        return Array.from(
            new Set(target.map((x: BasicPairInterface) => x.histidine_kinase))
        );
    } else if (colNum === 1) {
        return Array.from(
            new Set(target.map((x: BasicPairInterface) => x.response_regulator))
        );
    } else if (colNum === 2) {
        return Array.from(
            new Set(target.map((x: BasicPairInterface) => x.score))
        );
    } else {
        return [];
    }
}

export class MainPageAdapter {
    public selectionAdapter = (
        pairs: BasicPairInterface[]
    ): SelectablePair[] => {
        return pairs.map(pair => {
            return {...pair, selected: false};
        });
    };

    public createPairsMap(
        pairs: SelectablePair[] | undefined
    ): Map<string, SelectablePair> {
        const pairsMap = new Map<string, SelectablePair>();

        if (pairs) {
            pairs.forEach(pair =>
                pairsMap.set(
                    createMapKey(
                        pair.histidine_kinase,
                        pair.response_regulator
                    ),
                    pair
                )
            );
        }
        return pairsMap;
    }
}
