import React, {Component} from "react";
import "./HeatmapLegendStyle.css";

interface HeatmapLegendLabelItemComponentProps {
    value: number;
    height: number;
    legendSelection: (threshold: number) => void;
}

export class HeatmapLegendLabelItemComponent extends Component<
    HeatmapLegendLabelItemComponentProps
> {
    private readonly valueRounded: number;

    constructor(props: HeatmapLegendLabelItemComponentProps) {
        super(props);
        this.valueRounded = Math.round(10 * this.props.value) / 10;
        this.state = {
            hover: false,
        };
    }

    private onClick = () => {
        this.props.legendSelection(this.props.value);
    };

    render(): JSX.Element {
        return (
            <div className={"heatmap-legend-item"} onClick={this.onClick}>
                <span className={"heatmap-legend-item-tick"} />
                {this.valueRounded}
            </div>
        );
    }
}
