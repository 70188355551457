import {HeaderComponent} from "../MainPage/MainMenu/HeaderComponent";
import React from "react";
import {Col, Container, Image, Nav, Row} from "react-bootstrap";
import {useWindowSize} from "../../hooks/WindowSizeHook";
import {PageMode} from "../../hooks/usePageModeHook";
import {usePageMode} from "../../providers/PageModeProvider";

export function IntroductionPageComponent() {
    const size = useWindowSize();

    const {pageState, dispatch} = usePageMode();
    const {pageMode = PageMode.LARGESCREEN} = pageState;

    const fullHeight = 770;

    const renderFooterPanel = (): JSX.Element => {
        return (
            <>
                <a href={"https://www.utdallas.edu/"}>
                    <Image
                        src="./images/UT_Dallas_black_transp.png"
                        style={{
                            position: "fixed",
                            bottom: "220px",
                            left: "2em",
                            width: "160px",
                        }}
                    />
                </a>
                <a href={"http://morcoslaboratory.org"}>
                    <Image
                        src="./images/EVI_logoSquare.png"
                        style={{
                            position: "fixed",
                            bottom: "2em",
                            width: "160px",
                            left: "2em",
                        }}
                    />
                </a>
            </>
        );
    };

    const renderFooterPanel2 = (): JSX.Element => {
        return (
            <div
                style={{
                    borderTop: "1px solid #ececec",
                    backgroundColor: "#f7f7f7",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <a href={"https://www.utdallas.edu/"}>
                    <Image
                        src="./images/UT_Dallas_black_transp.png"
                        style={{
                            width: "200px",
                            margin: "50px 0px 50px 0px",
                        }}
                    />
                </a>
                <a href={"http://morcoslaboratory.org"}>
                    <Image
                        src="./images/EVI_logoSquare.png"
                        style={{
                            width: "200px",
                            margin: "0px 0px 50px 0px",
                        }}
                    />
                </a>
            </div>
        );
    };

    function render(): JSX.Element {
        let menuPanelStyle: any = {};
        let menuColumnStyle: any = {};
        const {width = 0, height = 0} = size;
        if (pageMode !== PageMode.MOBILE) {
            menuPanelStyle = {
                /*position: "fixed",
                top: "1em",*/
            };

            menuColumnStyle = {
                minHeight: "100vh",
                borderRight: "1px solid #ececec",
                backgroundColor: "#f7f7f7",
                position: "relative",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "flex",
                flex: "0 0 300px",
                width: "300px",
            };
        } else {
            menuColumnStyle = {
                borderRight: "1px solid #ececec",
                borderBottom: "1px solid #ececec",
                backgroundColor: "#f7f7f7",
                width: "100%",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "flex",
                flex: "0 0 100%",
                paddingBottom: "20px",
            };

            menuPanelStyle = {
                width: "100%",
            };
        }

        const renderLeftPanel = (): JSX.Element => {
            return (
                <div style={menuColumnStyle}>
                    <div style={menuPanelStyle}>
                        <HeaderComponent />

                        <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link href="#two-component">
                                Two-component systems
                            </Nav.Link>
                            <Nav.Link href="#basics-dca">
                                Basics to DCA
                            </Nav.Link>
                            <Nav.Link href="#dca-algorithm">
                                DCA algorithm
                            </Nav.Link>
                            <Nav.Link href="#scores">
                                How are the scores calculated?
                            </Nav.Link>
                            <Nav.Link href="#scores-meaning">
                                What do the scores mean?
                            </Nav.Link>
                            <Nav.Link href="#msa">
                                Derivation of the MSAs
                            </Nav.Link>
                            <Nav.Link href="#identifiers">
                                Organism identifiers
                            </Nav.Link>
                            <Nav.Link href="#loci">RR and HK loci</Nav.Link>
                            <Nav.Link href="#references">References</Nav.Link>
                        </Nav>

                        {pageMode !== PageMode.MOBILE && height > fullHeight
                            ? renderFooterPanel()
                            : undefined}
                    </div>
                </div>
            );
        };

        return (
            <div className={"main-page"}>
                <div id={"layout"} className={"main-page-content"}>
                    <Container fluid>
                        <Row style={{minHeight: "100vh"}}>
                            {renderLeftPanel()}
                            <Col xs={12} sm={10} md={7} lg={5}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "2em",
                                        paddingLeft: "50px",
                                    }}
                                />
                                <h1>
                                    <b>E</b>volutionary <b>L</b>inks <b>I</b>
                                    nferred for <b>H</b>istidine <b>K</b>inase{" "}
                                    <b>S</b>ensors <b>I</b>nteracting with{" "}
                                    <b>R</b>esponse regulators
                                </h1>
                                <div style={{width: "100%", height: "4em"}} />
                                <p style={{fontWeight: "bold"}}>
                                    Welcome to the ELIHKSIR web service! The
                                    ELIHKSIR web application offers insights
                                    about the probability of protein interaction
                                    specificity in two-component systems. The
                                    knowledge reported on this website is gained
                                    through statistical inference from genomic
                                    data. Through our measure H<sub>TCS</sub>
                                    <sup>(specific)</sup> we can also explore
                                    the effects of mutations. Read below for
                                    more details on how the metric is
                                    calculated.
                                </p>
                                <h2 id={"two-component"}>
                                    Two-component systems
                                </h2>
                                <p>
                                    Two component systems (TCS) are critical
                                    signal transduction machinery for adaptive
                                    responses in bacteria and archaea under
                                    various environmental conditions. TCS
                                    involves a histidine kinase (HK), which
                                    interacts specifically with its cognate
                                    partner, a response regulator (RR), by
                                    transferring a signal, such as a phosphoryl
                                    group, in response to a specific stimulus.
                                    The phosphorylated RR subsequently acts as a
                                    transcriptional activator or repressor.
                                    Through direct-coupling analysis (DCA), we
                                    identified the interface of highly coupled
                                    residues between HKs and RRs to predict
                                    cognate or non-cognate interactions between
                                    them.
                                </p>
                                <Image
                                    src="./images/tcs-interface.png"
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                    }}
                                />
                                <h2 id={"basics-dca"}>Basics to DCA</h2>
                                <p>
                                    As organisms evolve, point mutations in
                                    genes encoding functional proteins may
                                    result in a nonsynonymous codon change.
                                    Nonsynonymous codon changes lead to an amino
                                    acid residue change in the encoded protein.
                                    If the residue is important in the structure
                                    and/or function of the protein, then
                                    selective pressure is increased upon other
                                    residues that stabilize the intramolecular
                                    or intermolecular interaction in the
                                    wild-type protein(s). Thus, secondary
                                    mutations occur in other residues that
                                    complement the initial mutation in order to
                                    re-stabilize the protein, interface, or
                                    maintain function. This is referred to as
                                    coevolution.
                                </p>
                                <p>
                                    Direct-coupling analysis (DCA) is a method
                                    that exploits the coevolution of the amino
                                    acid residues to infer intramolecular and
                                    intermolecular residue interactions. DCA is
                                    a global statistical inference model and has
                                    been used for the study of coevolution in
                                    protein sequences with the ability to
                                    disentangle direct correlations from
                                    indirect correlations.{" "}
                                    <sup>
                                        <a href="#citation-1">1</a>
                                    </sup>{" "}
                                    With the usage of DCA, an approximation of
                                    the global probability distribution
                                    estimated from a large amount of sequences
                                    could be modeled for a set of residual
                                    positions in a sequence. This model
                                    accurately estimates the direct covariations
                                    between any two variables, such as pairwise
                                    residues within sequence, multiple lineages
                                    or evolutionary history, while excluding
                                    secondary correlations between dependent
                                    variables.
                                </p>
                                <Image
                                    src="./images/dca.png"
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                    }}
                                />
                                <h2 id={"dca-algorithm"}>DCA algorithm</h2>
                                <p>
                                    While a few derivations of DCA exist, the
                                    fast DCA algorithm was utilized to obtain
                                    coevolutionary information for HKs and RRs
                                    as it has been shown to accurately predict
                                    TCS interactions{" "}
                                    <sup>
                                        <a href="#citation-1">1</a>,{" "}
                                        <a href="#citation-2">2</a>,{" "}
                                        <a href="#citation-3">3</a>
                                    </sup>
                                    . Probability of the interaction occurring
                                    at the DCA-obtained interface is quantified
                                    using a score, explained later in this
                                    webpage.
                                </p>
                                <h2 id={"scores"}>
                                    How are the scores calculated?
                                </h2>
                                <p>
                                    Using DCA we can infer the coupling strength
                                    parameter e<sub>ij</sub>(A<sub>i</sub>, A
                                    <sub>j</sub>), with 0{"<"}i{"≤"}67 (length
                                    REC sequence) and 67
                                    {"<"}j{"≤"}
                                    179 (length HisKa + REC) for the sequence
                                    positions A<sub>i</sub>, and A<sub>j</sub>.
                                    The local field parameter, h<sub>i</sub>(A
                                    <sub>i</sub>
                                    ), is a 1 x 21 matrix that encodes the
                                    preference of an amino acid to be present at
                                    position A<sub>i</sub>. From these two
                                    parameters an objective function H
                                    <sub>TCS</sub> can be constructed:
                                </p>
                                <Image
                                    src="./images/score.png"
                                    style={{
                                        width: "80%",
                                        margin: "0 auto",
                                    }}
                                />
                                <p>
                                    From this energy function we can estimate
                                    the coevolutionary correlation or covariance
                                    strength. related to specificity, between
                                    residues in a HK’s HisKA domain and a RR’s
                                    REC domain by removing a randomized model:
                                </p>
                                <Image
                                    src="./images/score2.png"
                                    style={{
                                        width: "50%",
                                        margin: "0 auto",
                                    }}
                                />
                                <div style={{height: "1em"}}></div>
                                <p>With the randomized model:</p>
                                <Image
                                    src="./images/score3.png"
                                    style={{
                                        width: "50%",
                                        margin: "0 auto",
                                    }}
                                />
                                <h2 id={"scores-meaning"}>
                                    What do the scores mean? (high vs low)
                                </h2>
                                <p>
                                    A more negative H<sub>TCS</sub>
                                    <sup>(specific)</sup> indicates a stronger
                                    interaction specificity. For this reason the
                                    <b> axis of the legend is flipped</b>,
                                    indicating negative values at the top,
                                </p>
                                <h2 id={"msa"}>Derivation of the MSAs</h2>
                                <p>
                                    <br />
                                    Raw HMM profiles for HKs and RRs were
                                    obtained through Pfam’s hidden Markov models
                                    (HMM).{" "}
                                    <sup>
                                        <a href="#citation-4">4</a>,{" "}
                                        <a href="#citation-5">5</a>
                                    </sup>{" "}
                                    Then, the profile was searched using Hmmer's
                                    hmmsearch against the TrEMBL unreviewed
                                    database. HKs with a sequence gap of 5
                                    residues or larger were excluded from the
                                    MSA. RRs with a sequence gap of 6 residues
                                    or larger were excluded from the MSA.
                                </p>
                                <Image
                                    src="./images/msa.png"
                                    style={{
                                        width: "100%",
                                        margin: "0 auto",
                                    }}
                                />
                                <p>
                                    The 67x112 MSA was assembled by
                                    concatenating cognate HK-RR pairs from the
                                    previously obtained MSA sets, where cognate
                                    is defined by having adjacent loci.{" "}
                                    <sup>
                                        <a href="#citation-6">6</a>
                                    </sup>
                                </p>
                                <h2 id={"identifiers"}>
                                    Where do the organism identifiers come from?
                                </h2>
                                <p>
                                    Organism identifiers are taxonomic
                                    identifiers assigned by NCBI.{" "}
                                    <sup>
                                        <a href="#citation-7">7</a>,{" "}
                                        <a href="#citation-8">8</a>
                                    </sup>{" "}
                                    Uniprot refers to these identifiers as the
                                    mnemonic name. Many model organisms have a
                                    unique organism identifier, but uncommon
                                    species or sequences with limited species
                                    information may be clustered under a
                                    taxonomic identifier that contains multiple
                                    organisms. If two different organisms share
                                    the same identifier, this is because they
                                    are clustered under a larger taxonomic
                                    identifier (e.g. 9SYNE contains all
                                    Synechococcus species).
                                </p>
                                <h2 id={"loci"}>RR and HK loci:</h2>
                                <p>
                                    Loci are obtained from each protein’s
                                    Uniprot database entry. Loci that are
                                    adjacent in the organism’s genome are
                                    considered cognate.{" "}
                                    <sup>
                                        <a href="#citation-4">4</a>
                                    </sup>{" "}
                                    For proteins where the loci is unknown, loci
                                    information is displayed as unknown.
                                </p>


                                <p style={{"fontSize": "2em", margin: "50px 20px"}}><Nav.Link href="/tutorial" style={{"display": "inline", "padding": "0", color: "darkblue"}}>Continue to learn about ELIHKSIR in the Tutorial</Nav.Link>
                                </p>

                                <h4>Further information can be found in:</h4>
                                <ol id={"references"} className={"citations"}>
                                    <li id={"citation-1"}>
                                        Morcos F et al. “Direct-coupling
                                        analysis of residue coevolution captures
                                        native contacts across many protein
                                        families.” Proceedings of the National
                                        Academy of Sciences of the United States
                                        of America vol. 108,49 (2011):
                                        E1293-301. doi:10.1073/pnas.1111471108
                                    </li>
                                    <li id={"citation-2"}>
                                        Schug A, Weigt M, Onuchic JN, Hwa T,
                                        Szurmant H (2009) High-resolution
                                        protein complexes from integrating
                                        genomic information with molecular
                                        simulation. Proc Natl Acad Sci USA
                                        106:22124–22129.
                                    </li>
                                    <li id={"citation-3"}>
                                        Schug A, Weigt M, Hoch J, Onuchic J
                                        (2010) Computational modeling of
                                        phosphotransfer complexes in
                                        two-component signaling. Methods Enzymol
                                        471:43–58
                                    </li>
                                    <li id={"citation-4"}>
                                        Eddy SR (1998) Profile hidden Markov
                                        models. Bioinformatics 14:755–763
                                    </li>
                                    <li id={"citation-5"}>
                                        Finn RD, et al. (2010) The Pfam protein
                                        families database. Nucleic Acids Res 38:
                                        D211–D222
                                    </li>
                                    <li id={"citation-6"}>
                                        Williams, R.H., Whitworth, D.E. The
                                        genetic organisation of prokaryotic
                                        two-component system signalling
                                        pathways. BMC Genomics 11, 720 (2010)
                                        doi:10.1186/1471-2164-11-720
                                    </li>
                                    <li id={"citation-7"}>
                                        Sayers EW, Barrett T, Benson DA, Bryant
                                        SH, Canese K, Chetvernin V, Church DM,
                                        DiCuccio M, Edgar R, Federhen S, Feolo
                                        M, Geer LY, Helmberg W, Kapustin Y,
                                        Landsman D, Lipman DJ, Madden TL,
                                        Maglott DR, Miller V, Mizrachi I, Ostell
                                        J, Pruitt KD, Schuler GD, Sequeira E,
                                        Sherry ST, Shumway M, Sirotkin K,
                                        Souvorov A, Starchenko G, Tatusova TA,
                                        Wagner L, Yaschenko E, Ye J (2009).
                                        Database resources of the National
                                        Center for Biotechnology Information.
                                        Nucleic Acids Res. 2009 Jan;37(Database
                                        issue):D5-15. Epub 2008 Oct 21. [PubMed]
                                    </li>
                                    <li id={"citation-8"}>
                                        Benson DA, Karsch-Mizrachi I, Lipman DJ,
                                        Ostell J, Sayers EW (2009). GenBank.
                                        Nucleic Acids Res. 2009 Jan;37(Database
                                        issue):D26-31. Epub 2008 Oct 21.
                                        [PubMed]
                                    </li>
                                </ol>
                                <div style={{height: "5em"}} />
                            </Col>

                            {pageMode === PageMode.MOBILE
                                ? renderFooterPanel2()
                                : undefined}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }

    return render();
}
