import React, {Component} from "react";

interface MutationBackgroundErrorComponentProps {
    value: string;
}

export class MutationBackgroundErrorComponent extends Component<
    MutationBackgroundErrorComponentProps
> {
    private marginTop: number = 32;
    private marginBottom: number = 2;
    private width: number = 14.5;

    private renderHighlightDiv = (
        red: number,
        green: number,
        blue: number
    ): JSX.Element => {
        return (
            <div
                className={"mutation-letter-label"}
                style={{
                    width: this.width.toString() + "px",
                    height: "2px",
                    background: `rgba(${red},${green},${blue} ,0.8)`,
                    display: "inline-block",
                    marginTop: this.marginTop.toString(10) + "px",
                    marginBottom: this.marginBottom.toString(10) + "px",
                    flexGrow: 0,
                    flexShrink: 0,
                }}
            />
        );
    };

    public render(): JSX.Element {
        return (
            <div
                className={"mutation-background-wrapper"}
                style={{
                    marginTop: "4px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "440px",
                    height: "100%",
                    alignContent: "flex-start",
                }}
            >
                {this.props.value
                    .split("")
                    .map(char =>
                        char === "1"
                            ? this.renderHighlightDiv(255, 0, 0)
                            : this.renderHighlightDiv(255, 255, 255)
                    )}
            </div>
        );
    }
}
