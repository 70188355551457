import React, {Component} from "react";
import "./DragBoxComponentStyle.css";

interface DragBoxComponentState {
    mouseDown: boolean;
    startCoords?: Coords;
    endCoords?: Coords;
    selectionBox?: BoxShape;
}

interface DragBoxComponentProps {
    sendBoxData: (startCoords: any, endCoords: any) => void;
}

type Coords = {
    x: number;
    y: number;
};

type BoxShape = {
    left: number;
    top: number;
    width: number;
    height: number;
};

export class DragBox extends Component<
    DragBoxComponentProps,
    DragBoxComponentState
> {
    private readonly dragBoxRef: any;

    constructor(props: DragBoxComponentProps) {
        super(props);
        this.state = {
            mouseDown: false,
        };
        this.dragBoxRef = React.createRef();
    }

    componentDidMount(): void {}

    onMouseDown = (event: any) => {
        if (event.button !== 2) {
            this.setState({
                mouseDown: true,
                startCoords: {
                    x: event.pageX - window.scrollX,
                    y: event.pageY - window.scrollY,
                },
            });
            window.document.addEventListener("mousemove", this.onMouseMove);
            window.document.addEventListener("mouseup", this.onMouseUp);
        }
    };

    onMouseMove = (event: any) => {
        if (event.button !== 2) {
            event.preventDefault();
            if (this.state.mouseDown) {
                this.setState({
                    endCoords: {
                        x: event.pageX - window.scrollX,
                        y: event.pageY - window.scrollY,
                    },
                    selectionBox: this.getBoxShape(),
                });
            }
        }
    };

    onMouseUp = (event: any) => {
        window.document.removeEventListener("mousemove", this.onMouseMove);
        window.document.removeEventListener("mouseup", this.onMouseUp);
        if (this.state.endCoords && this.state.startCoords) {
            if (this.state.startCoords.x > this.state.endCoords.x) {
                let tempStart = this.state.startCoords;
                let tempEnd = this.state.endCoords;
                let temp = tempEnd.x;
                tempEnd.x = tempStart.x;
                tempStart.x = temp;
                this.setState({
                    startCoords: tempStart,
                    endCoords: tempEnd,
                });
            }
            if (this.state.startCoords.y > this.state.endCoords.y) {
                let tempStart = this.state.startCoords;
                let tempEnd = this.state.endCoords;
                let temp = tempEnd.y;
                tempEnd.y = tempStart.y;
                tempStart.y = temp;
                this.setState({
                    startCoords: tempStart,
                    endCoords: tempEnd,
                });
            }
        }
        if (this.state.endCoords) {
            this.props.sendBoxData(
                this.state.startCoords,
                this.state.endCoords
            );
        }

        this.setState({
            mouseDown: false,
            startCoords: undefined,
            endCoords: undefined,
            selectionBox: undefined,
        });
    };

    getBoxShape = (): BoxShape => {
        if (
            !this.state.mouseDown ||
            !this.state.startCoords ||
            !this.state.endCoords
        ) {
            return {
                left: -1,
                top: -1,
                width: -1,
                height: -1,
            };
        }
        const left = Math.min(this.state.startCoords.x, this.state.endCoords.x);
        const top = Math.min(this.state.startCoords.y, this.state.endCoords.y);
        const width = Math.abs(
            this.state.startCoords.x - this.state.endCoords.x
        );
        const height = Math.abs(
            this.state.startCoords.y - this.state.endCoords.y
        );
        return {
            left: left,
            top: top,
            width: width,
            height: height,
        };
    };

    render = (): JSX.Element => {
        return (
            <div className={"drag-box"} onMouseDown={this.onMouseDown}>
                {this.props.children}
                {this.renderSelectionBox()}
            </div>
        );
    };

    renderSelectionBox = (): any => {
        if (
            !this.state.mouseDown ||
            !this.state.startCoords ||
            !this.state.endCoords
        ) {
            return null;
        }

        return (
            <div
                ref={this.dragBoxRef}
                className="drag-box-border"
                style={this.state.selectionBox}
            />
        );
    };
}
