function getColorStops(gradientString: string) {
    // eslint-disable-next-line no-useless-escape
    let colorStopRegExp = /rgb[0-9\, \(\)]*%/g;
    return gradientString.match(colorStopRegExp);
}

function getRGBColor(colorStop: string) {
    let colorRegExp = /rgb\((.*?)\)/g;
    // @ts-ignore
    return colorRegExp
        .exec(colorStop)[1]
        .split(",")
        .map(val => parseInt(val.trim(), 10));
}

function getStopValue(colorStop: string) {
    let stopRegExp = /\) *([0-9]+) *%/g;
    // @ts-ignore
    return parseInt(stopRegExp.exec(colorStop)[1].trim(), 10);
}

function turnValuePercent(value: number, minValue: number, maxValue: number) {
    const base = maxValue - minValue;
    if (base === 0) {
        return 100;
    } else {
        return ((value - minValue) / base) * 100;
    }
}

function getColorBrackets(stopValues: Array<any>, colors: Array<any>) {
    let colorBrackets = stopValues.map((stopValue, i) => {
        return {stopValue, color: colors[i]};
    });
    colorBrackets.sort((a, b) => (a.stopValue > b.stopValue ? 1 : -1));
    return colorBrackets;
}

function findColorBrackets(value: number, colorBrackets: Array<any>) {
    for (let i = 0; i < colorBrackets.length; i++) {
        if (colorBrackets[i].stopValue > value) {
            return {
                finalColorStop: colorBrackets[i],
                initialColorStop: colorBrackets[i - 1],
            };
        }
    }

    return {
        finalColorStop: colorBrackets[colorBrackets.length - 1],
        initialColorStop: colorBrackets[colorBrackets.length - 2],
    };
}

function getColor(value: number, colorBrackets: any) {
    function getColorChannel(channel: number, colorBracket: any) {
        return {
            initialValue: colorBracket.initialColorStop.color[channel],
            finalValue: colorBracket.finalColorStop.color[channel],
            difference:
                colorBracket.finalColorStop.color[channel] -
                colorBracket.initialColorStop.color[channel],
        };
    }

    function interpolateColor(percent: number, colorChannelBase: any) {
        return (
            colorChannelBase.initialValue +
            percent * colorChannelBase.difference
        );
    }

    function turnValuePercent(
        value: number,
        minValue: number,
        maxValue: number
    ) {
        const base = maxValue - minValue;
        if (base === 0) {
            return 100;
        } else {
            return (value - minValue) / base;
        }
    }

    const perc = turnValuePercent(
        value,
        colorBrackets.initialColorStop.stopValue,
        colorBrackets.finalColorStop.stopValue
    );

    const r = parseInt(
        interpolateColor(perc, getColorChannel(0, colorBrackets)),
        10
    );
    const g = parseInt(
        interpolateColor(perc, getColorChannel(1, colorBrackets)),
        10
    );
    const b = parseInt(
        interpolateColor(perc, getColorChannel(2, colorBrackets)),
        10
    );

    const h = r * 0x10000 + g * 0x100 + b;
    return "#" + ("000000" + h.toString(16)).slice(-6);
}

export function getColorFromGradient(
    value: number,
    minValue: number,
    maxValue: number,
    gradient: string
) {
    const renormalizedValue = turnValuePercent(value, minValue, maxValue);
    // @ts-ignore
    const colors = getColorStops(gradient).map(colorStop =>
        getRGBColor(colorStop)
    );
    // @ts-ignore
    const stopValues = getColorStops(gradient).map(colorStop =>
        getStopValue(colorStop)
    );
    const colorBrackets = getColorBrackets(stopValues, colors);
    const colorBracket = findColorBrackets(renormalizedValue, colorBrackets);
    const color = getColor(renormalizedValue, colorBracket);
    return color;
}
