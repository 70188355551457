import React, {Component} from "react";
import {HeatMapRow} from "./Row/HeatMapRowComponent";
import {HeatMapLegend} from "./Legend/HeatMapLegendComponent";
import {HeatMapBottomLabel} from "./HeatMapBottomLabelComponent";
import "./HeatMapComponentStyle.css";
import {
    createMatrixArrayfromMap,
    MainPageAdapter,
    SelectablePair,
} from "../../requests/adapter";
import LabelTooltipComponent from "../ToolTip/LabelToolTipComponent";

export type coord = {
    x: number;
    y: number;
    histidine_kinase: string;
    response_regulator: string;
};

interface HeatMapComponentProps {
    pairsList?: SelectablePair[];
    names: string;
    gradient: string;
    title: string;
    labelsX: Array<string>;
    labelsY: Array<string>;
    pixelSize: number;
    setPixelRefs: (key: any, ref: any) => void;
    legendSelection: (threshold: number) => void;
    toggleItem: (histidine_kinase: string, response_regulator: string) => void;
    mutatePair: (histidine_kinase: string, response_regulator: string) => void;
    addHistogram: (
        data: Array<number>,
        label: string,
        labelsX: Array<string>,
        organism: string,
        labelBottom: string
    ) => void;
    overlaysBlocked: boolean;
}

export class HeatMapComponent extends Component<HeatMapComponentProps, {}> {
    private ready: boolean;
    private adapter: MainPageAdapter;

    constructor(props: HeatMapComponentProps) {
        super(props);
        this.ready = false;

        this.adapter = new MainPageAdapter();
    }

    public render(): JSX.Element {
        const {pairsList = []} = this.props;

        const pairsMap = this.adapter.createPairsMap(this.props.pairsList);

        let scoresGot = createMatrixArrayfromMap<
            SelectablePair,
            SelectablePair
        >(this.props.labelsX, this.props.labelsY, pairsMap);

        const maxVal = Math.max(...pairsList.map(pair => pair.score));
        const minVal = Math.min(...pairsList.map(pair => pair.score));

        return (
            <div className={"heatmap-outer-wrapper"}>
                <div className={"plot-wrapper"}>
                    <div className={"heatmap-title"}>{this.props.title}</div>
                    <div className={"heatmap-wrapper"}>
                        <div className={"rr-label-legend"}>
                            <div className={"rr-label-contents"}>
                                Response Regulators
                            </div>
                        </div>
                        <div className={"heatmap"}>
                            {scoresGot.map((dataRow, rowIndex) => {
                                const label_key = this.props.labelsY[rowIndex];
                                const display_label = pairsList.filter(
                                    pair =>
                                        pair.response_regulator === label_key
                                )[0].rr_display_name;
                                const label = display_label
                                    ? display_label
                                    : label_key;

                                return (
                                    <HeatMapRow
                                        key={this.props.labelsY[rowIndex]}
                                        title={this.props.title}
                                        min={minVal}
                                        max={maxVal}
                                        data={dataRow}
                                        pairsList={this.props.pairsList}
                                        label={this.props.labelsY[rowIndex]}
                                        displayLabel={label}
                                        labelsX={this.props.labelsX}
                                        displayLabelsX={this.props.labelsX.map(
                                            hk_name => {
                                                const hk_display_name = pairsList.filter(
                                                    pair =>
                                                        pair.histidine_kinase ===
                                                        hk_name
                                                )[0].hk_display_name;
                                                return hk_display_name
                                                    ? hk_display_name
                                                    : hk_name;
                                            }
                                        )}
                                        rowIndex={rowIndex}
                                        selectionLookUp={scoresGot.map(row =>
                                            row.map(pair => pair.selected)
                                        )}
                                        gradient={this.props.gradient}
                                        setPixelRefs={this.props.setPixelRefs}
                                        toggleItem={this.props.toggleItem}
                                        addHistogram={this.props.addHistogram}
                                        mutatePair={this.props.mutatePair}
                                        overlaysBlocked={
                                            this.props.overlaysBlocked
                                        }
                                    />
                                );
                            })}
                        </div>
                        <HeatMapLegend
                            key={this.props.title + "-title"}
                            numRow={scoresGot.length}
                            pixelSize={this.props.pixelSize}
                            gradient={this.props.gradient}
                            maxVal={maxVal}
                            minVal={minVal}
                            legendSelection={this.props.legendSelection}
                        />
                    </div>

                    <div className="heatmap-bottom-label-wrapper">
                        <div className={"heatmap-bottom-label-container"}>
                            {this.props.labelsX.map((labelX, labelIndex) => {
                                const label_hk_key = labelX;
                                const hk_display_label = pairsList.filter(
                                    pair =>
                                        pair.histidine_kinase === label_hk_key
                                )[0].hk_display_name;
                                const label_hk = hk_display_label
                                    ? hk_display_label
                                    : label_hk_key;

                                return (
                                    <LabelTooltipComponent
                                        name={labelX}
                                        organism={this.props.title}
                                        sequence={pairsList
                                            .filter(
                                                pair =>
                                                    pair.histidine_kinase ===
                                                    labelX
                                            )[0]
                                            .msa.substring(0, 64)}
                                        domain={
                                            pairsList.filter(
                                                pair =>
                                                    pair.histidine_kinase ===
                                                    labelX
                                            )[0].rr_domain
                                        }
                                        locus={
                                            pairsList.filter(
                                                pair =>
                                                    pair.histidine_kinase ===
                                                    labelX
                                            )[0].rr_locus
                                        }
                                        mutatePair={() => {}}
                                        addHistogram={() => {
                                            this.props.addHistogram(
                                                pairsList
                                                    .filter(
                                                        pair =>
                                                            pair.histidine_kinase ===
                                                            labelX
                                                    )
                                                    .map(pair => pair.score),
                                                labelX,
                                                pairsList
                                                    .filter(
                                                        pair =>
                                                            pair.histidine_kinase ===
                                                            labelX
                                                    )
                                                    .map(
                                                        pair =>
                                                            pair.response_regulator
                                                    ),
                                                this.props.title,
                                                "Response Regulators"
                                            );
                                        }}
                                        blocked={this.props.overlaysBlocked}
                                    >
                                        <div
                                            className={"click-wrapper"}
                                            onClick={() => {
                                                this.props.addHistogram(
                                                    pairsList
                                                        .filter(
                                                            pair =>
                                                                pair.histidine_kinase ===
                                                                labelX
                                                        )
                                                        .map(
                                                            pair => pair.score
                                                        ),
                                                    labelX,
                                                    pairsList
                                                        .filter(
                                                            pair =>
                                                                pair.histidine_kinase ===
                                                                labelX
                                                        )
                                                        .map(
                                                            pair =>
                                                                pair.response_regulator
                                                        ),
                                                    this.props.title,
                                                    "Response Regulators"
                                                );
                                            }}
                                        >
                                            <HeatMapBottomLabel
                                                key={labelIndex}
                                                data={label_hk}
                                            />
                                        </div>
                                    </LabelTooltipComponent>
                                );
                            })}
                            <div className={"heatmap-legend hidden"} />
                        </div>
                        <div className="heatmap-bottom-large-label">
                            Histidine Kinases
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
