import React, {Component} from "react";
import "./HistogramLegendStyle.css";

export type HistogramLegendComponentProps = {
    maxVal: number;
    minVal: number;
    labelNum: number;
    height: number;
};

type NonFunctionPropertyKeys<T> = {
    [P in keyof T]: T[P] extends Function ? never : P;
}[keyof T];

type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyKeys<T>>;

type HistogramLegendStyleProps = NonFunctionProperties<
    HistogramLegendComponentProps
>;

export class HistogramLegend extends Component<HistogramLegendComponentProps> {
    private static getHistogramLegendStyle(
        props: HistogramLegendStyleProps
    ): React.CSSProperties {
        return {
            display: "flex",
            flexDirection: "row",
            height: (props.height + 16).toString() + "px",
        };
    }

    private static getHistogramLegendBarStyle(
        props: HistogramLegendStyleProps
    ): React.CSSProperties {
        return {
            display: "inline-block",
            marginRight: "5px",
            marginLeft: "0px",
            marginTop: "12px",
            height: props.height.toString() + "px",
            border: "1px solid #757575",
        };
    }

    private labelVals = (): number[] => {
        const decrement =
            (Math.abs(this.props.maxVal) + Math.abs(this.props.minVal)) /
            (this.props.labelNum - 1);

        const labelVals: number[] = [];
        for (let i = 0; i < this.props.labelNum; i++) {
            labelVals.push(this.props.maxVal + i * decrement);
        }

        return labelVals;
    };

    private renderHistogramLegendLabel = (value: string): JSX.Element => {
        return (
            <div className="histogram-legend-item">
                {value}
                <span className={"histogram-legend-item-tick"} />
            </div>
        );
    };

    private getHistogramLegendLabelStyle = (
        props: HistogramLegendComponentProps
    ): React.CSSProperties => {
        return {
            display: "inline-block",
            height: (this.props.height + 16).toString() + "px",
            /*            position: "relative",*/
        };
    };

    private renderHistogramLegend = (): JSX.Element => {
        const labelVals: number[] = this.labelVals();

        return (
            <div
                className={"histogram-legend-label"}
                style={this.getHistogramLegendLabelStyle({
                    ...this.props,
                })}
            >
                <div className={"histogram-legend-labels-container"}>
                    {labelVals.map(data => {
                        return this.renderHistogramLegendLabel(data.toFixed(1));
                    })}
                </div>
            </div>
        );
    };

    public render(): JSX.Element {
        return (
            <div className="histogram-legend-wrapper">
                <div
                    className="histogram-legend"
                    style={HistogramLegend.getHistogramLegendStyle({
                        ...this.props,
                    })}
                >
                    <div className="histogram-legend-title-wrapper">
                        <div className="histogram-legend-title">
                            H<sub>TCS</sub>
                            <sup>(specific)</sup>
                        </div>
                    </div>
                    {this.renderHistogramLegend()}
                    <div
                        className="histogram-legend-bar"
                        style={HistogramLegend.getHistogramLegendBarStyle({
                            ...this.props,
                        })}
                    />
                </div>
            </div>
        );
    }
}
