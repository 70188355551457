import React, {Component} from "react";

export class NothingSelected extends Component {
    getNothingSelectedStyle = (): React.CSSProperties => {
        return {
            backgroundColor: "WhiteSmoke",
            padding: "30px 80px 30px 80px",
            marginRight: "10%",
            fontStyle: "italic",
            borderRadius: "10px",
            fontFamily: "Sans",
            textAlign: "center",
            fontSize: "1.2em",
            color: "Gray",
            transform: "translateX(30px)",
        };
    };

    getHintStyle = (): React.CSSProperties => {
        return {
            fontSize: "0.6em",
            fontStyle: "normal",
            width: "140px",
            margin: "15px auto 0",
            textAlign: "justify",
            fontWeight: "bold",
        };
    };

    render() {
        return (
            <div style={this.getNothingSelectedStyle()}>
                Nothing selected
                <div style={this.getHintStyle()}>
                    Please select some data points to export them as a CSV file.
                </div>
            </div>
        );
    }
}
