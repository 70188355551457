import React, {Component} from "react";
import "./Dropdown.css";
import {DDTableWrapper} from "./DDTableWrapper";
import {FormControl, InputGroup, ListGroup} from "react-bootstrap";
import {Organism} from "../../requests/RequestHandler";

type DropdownState = {
    listOpen: boolean;
    searchVal: string;
};

type DropdownItemProps = {
    title: string;
    filterChange: (filter: string) => void;
    itemClick: (id: number, uniprot: string, names: string) => void;
    dataIn: Organism[];
};

export default class Dropdown extends Component<
    DropdownItemProps,
    DropdownState
> {
    private timeout: any;

    constructor(props: DropdownItemProps) {
        super(props);
        this.timeout = 0;
        this.state = {
            listOpen: false,
            searchVal: "",
        };
    }

    handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
        let openFlag;
        const filter = event.currentTarget.value;

        filter.length < 1 ? (openFlag = false) : (openFlag = true);
        let temp: DropdownState = {
            searchVal: filter,
            listOpen: openFlag,
        };
        this.setState(temp);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.props.filterChange(filter);
        }, 300);
    };

    handleItemClick = (item: Organism) => {
        this.props.itemClick(item.id, item.uniprot, item.names);
        this.setState({listOpen: false, searchVal: item.names});
    };

    render(): JSX.Element {
        const {listOpen} = this.state;
        const constantOrganisms: Organism[] = [
            {
                id: 6597,
                key: "2",
                names: "SYNE7",
                uniprot: "SYNE7",
                description: "",
                displayName: "SYNE7 (S. elongatus)",
            },
            {
                id: 5090,
                key: "1",
                names: "ECOLI",
                uniprot: "ECOLI",
                description: "",
                displayName: "ECOLI (E. coli)",
            },
        ];
        return (
            <div className="dropdown-container">
                <InputGroup className="mb-3">
                    <FormControl
                        aria-describedby="basic-addon1"
                        placeholder={this.props.title}
                        onChange={this.handleFilterChange}
                        value={this.state.searchVal}
                        style={{textAlign: "center"}}
                    />
                </InputGroup>

                <ListGroup style={{marginBottom: "1em"}}>
                    {listOpen && (
                        <DDTableWrapper
                            data={this.props.dataIn}
                            itemClick={this.handleItemClick}
                            filter={this.state.searchVal}
                        />
                    )}
                </ListGroup>

                <ListGroup style={{marginBottom: "1em"}}>
                    <ListGroup.Item variant="dark">
                        Example Selection:
                    </ListGroup.Item>
                    <DDTableWrapper
                        data={constantOrganisms}
                        itemClick={this.handleItemClick}
                        filter={this.state.searchVal}
                    />
                </ListGroup>
            </div>
        );
    }
}
