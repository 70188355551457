import {APIFactory, OrganismListEndpoint} from "./EVLServerAPI";
import {
    BasicPairInterface,
    OrganismResponseInterface,
    MutationInterface,
    OrganismBase,
} from "./ResponseInterfaces";

import * as data from "./config.json";

import {
    AdvancedSearchRequestInterface,
    MultipleSequenceAlignment,
} from "./RequestInterfaces";

export interface Organism {
    id: number;
    key: string;
    names: string;
    uniprot: string;
    description: string;
    displayName: string;
}

export default class RequestHandler {
    private readonly url: string;
    private api = APIFactory;
    constructor() {
        this.url = data.url;
    }

    async getMatchingOrganismList(query: string): Promise<void | Organism[]> {
        const organismEndpoint = this.api("organism", this.url);
        return organismEndpoint.request({query}).then(response => {
            return response
                ? response.map(
                      (org: OrganismResponseInterface): Organism => {
                          return {
                              id: org.pk,
                              key: org.pk.toString(),
                              names: org.fields.names,
                              uniprot: org.fields.uniprot,
                              description: "",
                              displayName: org.fields.names,
                          };
                      }
                  )
                : undefined;
        });
    }

    async getPairsOfOrganism(id: number): Promise<BasicPairInterface[] | void> {
        const pairsEndpoint = this.api("pair", this.url);
        return pairsEndpoint
            .request({id})
            .then(response =>
                response ? response.map(pair => pair.fields) : undefined
            );
    }

    async getSequence(rr: string, hk: string): Promise<string | void> {
        const sequenceEndpoint = this.api("sequence", this.url);
        return sequenceEndpoint
            .request({response_regulator: rr, histidine_kinase: hk})
            .then(response => (response ? response.msa : undefined));
    }

    async getMutationEvaluation(
        sequences: MultipleSequenceAlignment[]
    ): Promise<MutationInterface[] | void> {
        const mutationEndpoint = APIFactory("mutation", this.url);
        return mutationEndpoint
            .postRequest({query: sequences})
            .then(response => (response ? response.response : undefined));
    }

    async getOrganism(): Promise<OrganismBase[] | void> {
        const organismListEndpoint = APIFactory(
            "organism-list",
            this.url
        ) as OrganismListEndpoint;
        return organismListEndpoint
            .request({})
            .then(response =>
                response ? response.map(organism => organism.fields) : undefined
            );
    }

    async getPairList(
        request: AdvancedSearchRequestInterface
    ): Promise<BasicPairInterface[] | void> {
        const pairListEndpoint = this.api("pairlist", this.url);
        return pairListEndpoint
            .postRequest(request)
            .then(response =>
                response ? response.map(pair => pair.fields) : undefined
            );
    }
}
