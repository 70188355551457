import React, {useRef, useState} from "react";
import {Button, Overlay, Popover} from "react-bootstrap";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface TooltipComponentProps {
    children: any;
    rr: string;
    hk: string;
    value: number;
    mutatePair: (histidine_kinase: string, response_regulator: string) => void;
    blocked: boolean;
}

export function TooltipComponent(props: TooltipComponentProps) {
    const [visible, setVisibility] = useState(false);
    const refContainer = useRef(null);

    function mutatePair() {
        setVisibility(false);
        return props.mutatePair(props.hk, props.rr);
    }

    let timeoutRef: number;

    const delayedShow = function() {
        if (!props.blocked) {
            timeoutRef = window.setTimeout(() => {
                setVisibility(true);
            }, 700);
        }
    };

    function stopShow() {
        window.clearTimeout(timeoutRef);
        setVisibility(false);
    }

    function render(): JSX.Element {
        let myTooltip = (
            <Popover
                id={123}
                onMouseEnter={() => setVisibility(true)}
                onMouseLeave={() => setVisibility(false)}
            >
                <Popover.Title as="h3">{`Score`}</Popover.Title>
                <Popover.Content>
                    Response Regulator : {props.rr} <br />
                    Histidine Kinase : {props.hk} <br />
                    Hamiltonian Score : {props.value} <br /> &nbsp; <br />
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={mutatePair}
                        block
                    >
                        <FontAwesomeIcon icon={faExchangeAlt} /> MUTATE
                    </Button>
                </Popover.Content>
            </Popover>
        );

        return (
            <>
                <div
                    ref={refContainer}
                    onMouseEnter={delayedShow}
                    onMouseLeave={stopShow}
                >
                    {props.children}
                </div>

                <Overlay
                    show={visible}
                    onHide={() => setVisibility(false)}
                    placement="right"
                    // @ts-ignore
                    target={refContainer.current}
                >
                    {myTooltip}
                </Overlay>
            </>
        );
    }

    return render();
}

export default TooltipComponent;
