// Called by HistogramComponent

import React, {Component} from "react";
import HBarComponent from "./HBarComponent";
import "./HistogramStyle.css";
import {getColorFromGradient} from "../../utils/colorGradient";

interface HBarContainerComponentProps {
    data: Array<number>;
    labels: Array<string>;
    barWidth: number;
    barMaxHeight: number;
    gradient: string;
}

interface HBarContainerComponentStates {}

export default class HBarContainerComponent extends Component<
    HBarContainerComponentProps,
    HBarContainerComponentStates
> {
    private maxVal: number;
    private minVal: number;

    constructor(props: HBarContainerComponentProps) {
        super(props);
        this.maxVal = Math.max(...this.props.data);
        this.minVal = Math.min(...this.props.data);
    }

    render(): JSX.Element {
        let max = Math.max(...this.props.data);
        let min = Math.min(...this.props.data);
        let zeroData = this.props.data.map(x => {
            return x - min + 1;
        });
        let normalMax = Math.max(...zeroData);
        let normalizedData = zeroData.map(x => {
            return normalMax / x;
        });
        return (
            // TODO : place label here
            <div className={"histogram-bar-container"}>
                {this.props.data.map((dataPoint, columnIndex) => {
                    // TODO : implement selection here
                    return (
                        <HBarComponent
                            key={columnIndex}
                            maxVal={max}
                            color={getColorFromGradient(
                                dataPoint,
                                min,
                                max,
                                this.props.gradient
                            )}
                            normMaxVal={normalMax}
                            normVal={normalizedData[columnIndex]}
                            value={dataPoint}
                            name={this.props.labels[columnIndex]}
                            width={this.props.barWidth}
                            maxHeight={this.props.barMaxHeight}
                        />
                    );
                })}
            </div>
        );
    }
}
