import React, {useRef, useState} from "react";
import {Button, Overlay, Popover} from "react-bootstrap";
import {
    faChartBar,
    faExternalLinkAlt,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface LabelTooltipComponentProps {
    children: any;
    name: string;
    organism: string;
    sequence: string;
    domain: string;
    locus: string;
    mutatePair: (histidine_kinase: string, response_regulator: string) => void;
    addHistogram: () => void;
    blocked: boolean;
}

export function LabelTooltipComponent(props: LabelTooltipComponentProps) {
    const [visible, setVisibility] = useState(false);
    const refContainer = useRef(null);

    let timeoutRef: number;

    const delayedShow = function() {
        if (!props.blocked) {
            timeoutRef = window.setTimeout(() => {
                setVisibility(true);
            }, 700);
        }
    };

    function stopShow() {
        window.clearTimeout(timeoutRef);
        setVisibility(false);
    }

    function render(): JSX.Element {
        let myTooltip = (
            <Popover
                id={123}
                onMouseEnter={() => setVisibility(true)}
                onMouseLeave={() => setVisibility(false)}
            >
                <Popover.Title as="h3">
                    {props.name}{" "}
                    <a
                        target="_blank"
                        href={`https://www.uniprot.org/uniprot/${props.name}_${props.organism}`}
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                </Popover.Title>
                <Popover.Content>
                    Domain : {props.domain} <br />
                    Locus : {props.locus} <br />
                    Sequence : <br />
                    <div
                        style={{
                            backgroundColor: "#eaeaea",
                            padding: "10px",
                            marginTop: "10px",
                            marginBottom: "15px",
                        }}
                    >
                        <span
                            style={{
                                lineBreak: "anywhere",
                                fontFamily:
                                    '"Lucida Console", Monaco, monospace',
                            }}
                        >
                            {props.sequence}
                        </span>
                    </div>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={props.addHistogram}
                        block
                    >
                        <FontAwesomeIcon icon={faChartBar} /> ADD HISTOGRAM
                    </Button>
                    {/*                    <Button
                        variant="primary"
                        size="sm"
                        onClick={mutatePair}
                        block
                    >
                        MUTATE
                    </Button>*/}
                </Popover.Content>
            </Popover>
        );

        return (
            <>
                <div
                    ref={refContainer}
                    onMouseEnter={delayedShow}
                    onMouseLeave={stopShow}
                >
                    {props.children}
                </div>

                <Overlay
                    show={visible}
                    onHide={() => setVisibility(false)}
                    placement="right"
                    // @ts-ignore
                    target={refContainer.current}
                >
                    {myTooltip}
                </Overlay>
            </>
        );
    }

    return render();
}

export default LabelTooltipComponent;
