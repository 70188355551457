import React, {Component} from "react";
import {HeatmapLegendLabelItemComponent} from "./HeatmapLegendLabelItemComponent";
import "./HeatmapLegendStyle.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faLongArrowAltLeft,
    faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";

interface HeatmapLegendLabelComponentProps {
    maxVal: number;
    minVal: number;
    height: number;
    pixelSize: number;
    labelNum: number;
    legendSelection: (threshold: number) => void;
}

export class HeatmapLegendLabelComponent extends Component<
    HeatmapLegendLabelComponentProps
> {
    getHeatMapLegendLabelStyle(
        props: HeatmapLegendLabelComponentProps
    ): React.CSSProperties {
        return {
            display: "inline-block",
            height: this.props.height.toString() + "px",
            position: "relative",
        };
    }

    render(): JSX.Element {
        const increment =
            (Math.abs(this.props.maxVal) + Math.abs(this.props.minVal)) /
            (this.props.labelNum - 1);

        const labelVals = [];
        for (let i = 0; i < this.props.labelNum; i++) {
            labelVals.push(this.props.minVal + i * increment);
        }
        return (
            <div
                className={"heatmap-legend-label"}
                style={this.getHeatMapLegendLabelStyle({
                    maxVal: this.props.maxVal,
                    minVal: this.props.minVal,
                    height: this.props.height,
                    pixelSize: this.props.pixelSize,
                    labelNum: this.props.labelNum,
                    legendSelection: this.props.legendSelection,
                })}
            >
                <div className={"heatmap-legend-label-item-container"}>
                    {labelVals.map(data => {
                        return (
                            <HeatmapLegendLabelItemComponent
                                key={data}
                                value={data}
                                height={this.props.pixelSize}
                                legendSelection={this.props.legendSelection}
                            />
                        );
                    })}
                </div>

                <div className={"heatmap-legend-hints-container"}>
                    <div className="heatmap-legend-anchor">
                        <div className="heatmap-legend-hint">
                            more specific &nbsp;
                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                    </div>
                    <div className="heatmap-legend-anchor">
                        <div className="heatmap-legend-hint">
                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                            &nbsp; less specific
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
