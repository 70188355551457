import React, {Component} from "react";
import "./HeaderComponentStyle.css";
import {Image, Nav} from "react-bootstrap";

interface HeaderComponentProps {
    heatMapOrganismList?: string[];
}

export class HeaderComponent extends Component<HeaderComponentProps> {
    public render(): JSX.Element {
        return (
            <div style={{width: "100%"}}>
                <div
                    className={"menu-wrapper"}
                    style={{
                        fontSize: "1.8em",
                        width: "100%",
                        borderBottom: "1px solid #ececec",
                    }}
                >
                    <Nav defaultActiveKey="/" className="flex-column">
                        <Nav.Link href="/run">
                            <Image
                                src="./images/elihksir-logo.png"
                                style={{
                                    width: "240px",
                                }}
                                alt={"Run ELIHKSIR"}
                            />
                        </Nav.Link>
                        <Nav.Link href="/run">Run ELIHKSIR</Nav.Link>
                        <Nav.Link href="/">Introduction</Nav.Link>
                        <Nav.Link href="/tutorial">Tutorial</Nav.Link>
                    </Nav>
                </div>
                <div style={{height: "2em"}} />
            </div>
        );
    }
}
