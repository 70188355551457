import React, {Component} from "react";

interface HeatMapBottomLabelComponentProps {
    data: string;
    selected?: boolean;
}

export class HeatMapBottomLabel extends Component<
    HeatMapBottomLabelComponentProps
> {
    getLabelHoverStyle = (): React.CSSProperties => {
        return {color: "DeepSkyBlue"};
    };

    render(): JSX.Element {
        let styleOf: any;
        this.props.selected
            ? (styleOf = this.getLabelHoverStyle())
            : (styleOf = {});
        return (
            <div className={"heatmap-bottom-label"} style={styleOf}>
                {this.props.data}
            </div>
        );
    }
}
