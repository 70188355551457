import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    useContext,
} from "react";
import usePageModeHook, {
    PageMode,
    PageModeAction,
    PageModeActionType,
    PageState,
} from "../hooks/usePageModeHook";
import {useWindowSize} from "../hooks/WindowSizeHook";

export interface PageModeContextInterface {
    pageState: PageState;
    dispatch?: Dispatch<PageModeAction>;
}

export interface StateProviderProps {}

const initialState = {
    pageMode: PageMode.LARGESCREEN,
};

export let PageModeContext: React.Context<PageModeContextInterface>;
PageModeContext = createContext({pageState: initialState});

export enum BREAKPOINT {
    PHONE = 800, //576,
    SMALLSCREEN = 1300,
}

export const PageModeProvider: FunctionComponent<StateProviderProps> = props => {
    const [pageState, dispatch] = usePageModeHook(initialState);
    const size = useWindowSize();

    const {width = 0, height = 0} = size;

    let newPageMode: PageMode = initialState.pageMode;
    if (width < BREAKPOINT.PHONE) {
        newPageMode = PageMode.MOBILE;
    } else if (BREAKPOINT.PHONE < width && width < BREAKPOINT.SMALLSCREEN) {
        newPageMode = PageMode.SMALLSCREEN;
    } else if (BREAKPOINT.SMALLSCREEN < width) {
        newPageMode = PageMode.LARGESCREEN;
    }

    if (pageState.pageMode !== newPageMode) {
        dispatch({
            type: PageModeActionType.CHANGE,
            newPageMode: newPageMode,
        });
    }

    return (
        <PageModeContext.Provider value={{pageState, dispatch}}>
            {props.children}
        </PageModeContext.Provider>
    );
};

export const usePageMode = () => useContext(PageModeContext);
